import React, { useState } from 'react'
import { Segment, Form, Button, Modal } from 'semantic-ui-react'
import Section from './Section'
import Title from './Title'
import TitleParagraph from './TitleParagraph'
import ImageWithCaption from './ImageWithCaption'
import TwoImageWithCaption from './TwoImageWithCaption'
import TwoImageWithTwoCaption from './TwoImageWithTwoCaption'
import TitleParagraphImage from './TitleParagraphImage'
import TitleImageParagraph from './TitleImageParagraph'
import Ad from './Ad'
import Author from './Author'
import Credits from './Credits'
import Paragraph from './Paragraph'
import Information from './Information'
import PublicationView from './../PublicationView'

const Publication = (props) => {

  const { page, selectedPage, onChangePage, selectedPublicationType, onChangeSelectedPublicationType, evenPage, oddPage } = props
  const [open, setOpen] = useState(false)

  const typeComponent = [
    { text: 'Sección', value: 'section' },
    { text: 'Imagen con leyenda', value: 'imageWithCaption' },
    { text: 'Título', value: 'title' },
    { text: 'Párrafo', value: 'paragraph' },
    { text: 'Título con párrafo', value: 'titleParagraph' },
    { text: 'Publicidad', value: 'ad' },
    { text: 'Autor', value: 'author' },
    { text: 'Créditos', value: 'credits' },
    { text: 'Título, párrafo e imagen', value: 'titleParagraphImage' },
    { text: 'Título, imagen y párrafo', value: 'titleImageParagraph' },
    { text: 'Dos imagenes con leyenda', value: 'twoImageWithCaption' },
    { text: 'Dos imagenes con dos leyendas', value: 'twoImageWithTwoCaption' }
  ]

  const onAddComponent = () => {
    if (!page.data) {
      page.data = []
    }
    if(selectedPublicationType==='title'){
      page.data.push({
        type: selectedPublicationType,
        color: '#005f25'
      })
    }else{
      page.data.push({
        type: selectedPublicationType
      })
    }
    onChangePage(selectedPage, page)
  }

  const onChangeComponent = (value, index, field) => {
    page.data[index][field] = value
    onChangePage(selectedPage, page)
  }

  const onChangeCustomTitle = (typography, color, bold, margin, index) => {
    page.data[index].typography = typography
    page.data[index].color = color
    page.data[index].bold = bold
    page.data[index].margin = margin
    onChangePage(selectedPage, page)
  }

  const onClickRemove = (index) => {
    page.data.splice(index, 1)
    onChangePage(selectedPage, page)
  }

  const onChangeMagazinePage = (value, field) => {
    page[field] = value
    onChangePage(selectedPage, page)
  }

  const onClickUp = (key) => {
    let size = page.data.length
    let position = Number(key)-1
    if(position!==-1){
      let data = []
      let component = page.data[key]
      page.data.splice(key, 1)
      for (var index = 0; index < size; index++) {
        if(index === position){
          data.push(component)
        }else{
          data.push(page.data.shift())
        }
      }
      page.data = data
      onChangePage(selectedPage, page)
    }
  }

  const onClickDown = (key) => {
    let size = page.data.length
    let position = Number(key)+1
    if(position!==size){
      let data = []
      let component = page.data[key]
      page.data.splice(key, 1)
      for (var index = 0; index < size; index++) {
        if(index === position){
          data.push(component)
        }else{
          data.push(page.data.shift())
        }
      }
      page.data = data
      onChangePage(selectedPage, page)
    }
  }

  let components = null

  if (page.data) {
    components = page.data.map((value, index) => {
      const { type } = value
      if (type === 'section') {
        return <Section key={index} page={selectedPage} value={value} index={index}
          onChange={onChangeComponent} onClick={onClickRemove} onClickUp={onClickUp} onClickDown={onClickDown} />
      }
      if (type === 'title') {
        return <Title key={index} page={selectedPage} value={value} index={index} onChangeCustomTitle={onChangeCustomTitle}
          onChange={onChangeComponent} onClick={onClickRemove} onClickUp={onClickUp} onClickDown={onClickDown} />
      }
      if (type === 'paragraph') {
        return <Paragraph key={index} page={selectedPage} value={value} index={index}
          onChange={onChangeComponent} onClick={onClickRemove} onClickUp={onClickUp} onClickDown={onClickDown} />
      }
      if (type === 'titleParagraph') {
        return <TitleParagraph key={index} page={selectedPage} value={value} index={index}
          onChange={onChangeComponent} onClick={onClickRemove} onClickUp={onClickUp} onClickDown={onClickDown} />
      }
      if (type === 'imageWithCaption') {
        return <ImageWithCaption key={index} page={selectedPage} index={index} value={value}
          onChange={onChangeComponent} onClick={onClickRemove} onClickUp={onClickUp} onClickDown={onClickDown} />
      }
      if (type === 'twoImageWithCaption') {
        return <TwoImageWithCaption key={index} page={selectedPage} index={index} value={value}
          onChange={onChangeComponent} onClick={onClickRemove} onClickUp={onClickUp} onClickDown={onClickDown} />
      }
      if (type === 'twoImageWithTwoCaption') {
        return <TwoImageWithTwoCaption key={index} page={selectedPage} index={index} value={value}
          onChange={onChangeComponent} onClick={onClickRemove} onClickUp={onClickUp} onClickDown={onClickDown} />
      }
      if (type === 'titleParagraphImage') {
        return <TitleParagraphImage key={index} page={selectedPage} index={index} value={value}
          onChange={onChangeComponent} onClick={onClickRemove} onClickUp={onClickUp} onClickDown={onClickDown} />
      }
      if (type === 'titleImageParagraph') {
        return <TitleImageParagraph key={index} page={selectedPage} index={index} value={value}
          onChange={onChangeComponent} onClick={onClickRemove} onClickUp={onClickUp} onClickDown={onClickDown} />
      }
      if (type === 'ad') {
        return <Ad key={index} page={selectedPage} index={index} value={value}
          onChange={onChangeComponent} onClick={onClickRemove} onClickUp={onClickUp} onClickDown={onClickDown} />
      }
      if (type === 'author') {
        return <Author key={index} page={selectedPage} index={index} value={value}
          onChange={onChangeComponent} onClick={onClickRemove} onClickUp={onClickUp} onClickDown={onClickDown} />
      }
      if (type === 'credits') {
        return <Credits key={index} page={selectedPage} value={value} index={index}
          onChange={onChangeComponent} onClick={onClickRemove} onClickUp={onClickUp} onClickDown={onClickDown} />
      }
      return null
    })
  }

  return (
    <React.Fragment>
      <Segment>
        Información principal de la Publicación<br/><br/>
        <Information page={page} onChange={onChangeMagazinePage} />
      </Segment>
      {components}
      <Form>
        <Form.Group widths='equal'>
          <Form.Select options={typeComponent} placeholder='Tipo'
            onChange={(e, data) => { onChangeSelectedPublicationType(data.value) }}
            value={selectedPublicationType} />
          <Button secondary disabled={selectedPublicationType === null} onClick={onAddComponent} >Agregar</Button>
        </Form.Group>
      </Form>
      <Modal
        size='tiny'
        open={open}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
      >
        <Modal.Header>Previsualizar</Modal.Header>
        <Modal.Content style={{backgroundColor: '#59514e'}}>
          <PublicationView page={page} selectedPage={selectedPage} evenPage={evenPage} oddPage={oddPage}/>
        </Modal.Content>
      </Modal>
    </React.Fragment>
  )
}

export default Publication