import React from 'react';
import { Segment, Form, Checkbox, Button, Icon } from 'semantic-ui-react'
import ImageUpload from '../components/ImageUpload'

const ContentItem = (props) => {

  const { number, pageNumber, text, badge, image, index, onChange, onClickDeleteDataPage, onClickUp, onClickDown } = props

  const onChangeImage = (value) => {
    onChange(value, 'image', index)
  }

  return (
    <Segment clearing>
      <Button size={'mini'} icon negative floated='right' onClick={()=>onClickDeleteDataPage(index)} >
        <Icon name='remove' />
      </Button>
      <Button size={'mini'}  icon basic floated='right' onClick={()=> onClickDown(index)}>
        <Icon name='chevron down' />
      </Button>
      <Button size={'mini'}  icon basic floated='right' onClick={()=> onClickUp(index)}>
        <Icon name='chevron up' />
      </Button>
      <br/><br/>
      <Form>
        <Form.Group widths='equal'>
          <Form.Input label='Número' placeholder='Número' value={number} width={2} 
            onChange={(e) => onChange(e.target.value, 'number', index)} />
          <Form.Input label='Texto' placeholder='Texto' value={text} 
            onChange={(e) => onChange(e.target.value, 'text', index)} />
        </Form.Group>
        <Form.Group widths='equal'>
          <Form.Input label='Página' placeholder='Página' value={pageNumber} width={2}  pattern="[0-9]*" 
            onChange={(e) => onChange((e.target.value ? parseInt(e.target.value) : 0), 'page', index)} />
          <ImageUpload image={image} onChange={onChangeImage} />
        </Form.Group>
        <Form.Field>
          <Checkbox label='Resaltar' checked={badge?badge : false} onClick={(event, data) => {onChange(data.checked, 'badge', index)}} />
        </Form.Field>
      </Form>
    </Segment>
  )
}

export default ContentItem