import React, { useState } from 'react'
import { Grid, Segment, Form, Button, Icon, Header, Modal } from 'semantic-ui-react'
import { postonTemplate } from '../Magazine/MagazinePage/Poston/Template'
import CoverView from './MagazinePage/CoverView'
import ContentView from './MagazinePage/ContentView'
import AdView from './MagazinePage/AdView'
import PublicationView from './MagazinePage/PublicationView'
import html2pdf from 'html2pdf.js'

const MagazineListPage = (props) => {

  const { magazine, selectedPage, selectedPageType, onChangeState } = props
  const [open, setOpen] = useState({modal: false, page: null, pageKey: null})
  
  const typePage = [
    { text: 'Portada', value: 'cover' },
    { text: 'Indice', value: 'content' },
    { text: 'Aviso', value: 'ad' },
    { text: 'Publicación', value: 'publication' },
    { text: 'Poston', value: 'poston' }
  ]

  const onClickAddPage = () => {
    let page = {
      type: selectedPageType,
      title: '',
      bookmark: false
    }
    if (!magazine.pages) {
      magazine.pages = []
    }
    if (selectedPageType === 'cover' || selectedPageType === 'ad') {
      page.data = {
        image: {}
      }
      if(selectedPageType === 'ad') {
        page.data = {
          image: {},
          link: ''
        }
      }
    } else if(selectedPageType === 'content') {
     page.data = []
    } else if(selectedPageType === 'poston') {
      page = postonTemplate
    }
    magazine.pages.push(page)
    onChangeState({ selectedPageType: null, magazine: magazine, selectedPage: null })
  }

  const onClickDeletePage = (key) => {
    magazine.pages.splice(key, 1)
    onChangeState({ selectedPageType: null, magazine: magazine, selectedPage: null })
  }

  const onClickViewPage = (key) => {
    onChangeState({ selectedPage: key })
  }

  const onClickPreviewPage = (key) => {
    setOpen({modal:true, page: magazine.pages[key], pageKey: key})
  }

  const onChangeSelectedPageType = (value) => {
    onChangeState({ selectedPageType: value })
  }

  const onClickUpPage = (key) => {
    let size = magazine.pages.length
    let position = Number(key)-1
    if(position!==-1){
      let pages = []
      let page = magazine.pages[key]
      magazine.pages.splice(key, 1)
      for (var index = 0; index < size; index++) {
        if(index === position){
          pages.push(page)
        }else{
          pages.push(magazine.pages.shift())
        }
      }
      magazine.pages = pages
      onChangeState({ selectedPageType: null, magazine: magazine, selectedPage: null })
    }
  }

  const onClickDownPage = (key) => {
    let size = magazine.pages.length
    let position = Number(key)+1
    if(position!==size){
      let pages = []
      let page = magazine.pages[key]
      magazine.pages.splice(key, 1)
      for (var index = 0; index < size; index++) {
        if(index === position){
          pages.push(page)
        }else{
          pages.push(magazine.pages.shift())
        }
      }
      magazine.pages = pages
      onChangeState({ selectedPageType: null, magazine: magazine, selectedPage: null })
    }
  }

  const onExportPDF = () =>{
    var element = document.getElementById('magazine-pdf')
    const {width,height} = element.getBoundingClientRect()
    const numerPage = (parseInt(open.pageKey)+1)
    const filename = magazine.editionName.replace(' ','_')+'_'+numerPage+'.pdf'
    var opt = {
      filename: filename,
      jsPDF: { unit: 'px', format: [width, height+1] }
    }
    html2pdf().from(element).set(opt).save()
  }

  return (
    <Grid.Column width={4} style={{ position: 'fixed', top: '190px', bottom: '0', overflowY: 'auto' }}>
      <Pages pages={magazine.pages} onClickDeletePage={onClickDeletePage} onClickViewPage={onClickViewPage} 
        onClickUpPage={onClickUpPage} onClickDownPage={onClickDownPage} onClickPreviewPage={onClickPreviewPage}
        selectedPage={selectedPage} />
      <Form>
        <Form.Group widths='equal'>
          <Form.Select options={typePage} placeholder='Tipo' 
            onChange={(e, data)=>{ onChangeSelectedPageType(data.value) }}
            value={selectedPageType} />
          <Button secondary icon fluid onClick={onClickAddPage} disabled={selectedPageType===null}>
            <Icon name='add' />
          </Button>
        </Form.Group>
      </Form>
      <Modal
        size='tiny'
        open={open.modal}
        onClose={() => setOpen({modal: false, page: null, pageKey: null})}
      >
        <Modal.Header>Previsualizar</Modal.Header>
        <Modal.Content style={{backgroundColor: '#59514e'}}>
          <div style={{width: '360px', margin: 'auto', marginBottom: '15px'}}>
            <Button onClick={onExportPDF} secondary >Exportar a PDF</Button>
          </div>
          <ModalPageType page={open.page} pageKey={open.pageKey} 
            evenPage={magazine.evenPage} oddPage={magazine.oddPage} />
        </Modal.Content>
      </Modal>
    </Grid.Column>
  )
}

const ModalPageType = ({page, pageKey, evenPage, oddPage}) => {

  if(page.type === 'cover'){
    return(
      <CoverView page={page} />
    )
  }
  if(page.type === 'content'){
    return(
      <ContentView page={page} />
    )
  }
  if(page.type === 'ad'){
    return(
      <AdView page={page} />
    )
  }
  if(page.type === 'publication'){
    return(
      <PublicationView page={page} selectedPage={pageKey} 
        evenPage={evenPage} oddPage={oddPage} />
    )
  }

}


const Pages = (props) => {

  const {pages, onClickDeletePage, onClickViewPage, onClickPreviewPage,
    selectedPage, onClickUpPage, onClickDownPage} = props

  return pages?Object.keys(pages).map((key, index) =>{
    let name = ''
    let mark = null
    let secondary = false
    if(pages[key].type === 'cover'){
      name = 'Portada'      
    }
    if(pages[key].type === 'content'){
      name = 'Indice'      
    }
    if(pages[key].type === 'ad'){
      name = 'Aviso'      
    }
    if(pages[key].type === 'publication'){
      name = 'Publicación'
    }
    if(pages[key].type === 'poston'){
      name = 'Poston'      
    }
    if(pages[key].bookmark){
      mark = <Icon disabled name='bookmark' />
    }
    if(key===selectedPage){
      secondary = true
    }
    return (<Segment clearing key={index} secondary={secondary} >
      <Button size={'mini'} icon negative floated='right' onClick={()=> onClickDeletePage(key)} >
        <Icon name='remove' />
      </Button>
      <Button size={'mini'}  icon floated='right' onClick={()=> onClickViewPage(key)}>
        <Icon name='edit' />
      </Button>
      <Button size={'mini'}  
      floated='right' onClick={()=> onClickPreviewPage(key)}>
        Previsualizar
      </Button>
      <Button size={'mini'}  icon basic floated='right' onClick={()=> onClickDownPage(key)}>
        <Icon name='chevron down' />
      </Button>
      <Button size={'mini'}  icon basic floated='right' onClick={()=> onClickUpPage(key)}>
        <Icon name='chevron up' />
      </Button>
      <Header sub style={{marginTop: '40px'}}>{parseInt(key)+1} {name}{mark}</Header>
      <span>{pages[key].title}</span>
    </Segment>)
  }):null
}

export default MagazineListPage