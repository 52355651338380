import React from 'react';
import { Form, Button, Icon } from 'semantic-ui-react'
import { InputFile } from 'semantic-ui-react-input-file'
import { useParams } from 'react-router-dom'

const PDFUpload = (props) => {

  const { key } = useParams()
  const { pdf, onChange } = props

  const handleUpload = (event) => {

    const pdfUpload = event.target.files[0]

    if(pdfUpload){
      const path = key + '/' + pdfUpload.name
      const file = pdfUpload
      onChange(path, file)
    }else{
      onChange(pdf, null)
    }
  }

  const changePath = (path) => {
    if(!path){
      return 'Cargar PDF'
    }
    let index = path.lastIndexOf("/")
    return path.substring(index + 1)
  }

  let labelPath = pdf?changePath(pdf): 'Cargar PDF'

  return (
    <Form.Field style={{float:'right'}} >
      <div className="field">
        <InputFile
          button={{ primary: true, label: labelPath }}
          input={{
            onChange: handleUpload,
            accept: "application/pdf"
          }}
        />
        <Button size={'mini'} icon onClick={()=> onChange(null, null)} >
          <Icon name='remove' />
        </Button>
      </div>
    </Form.Field>
  )
}

export default PDFUpload