import React from 'react'
import { Segment, Form, Label, Button, Icon } from 'semantic-ui-react'

const Institute = (props) => {

  const { value, index, onChange, onClick, onClickUp, onClickDown } = props

  let name = value.name ? value.name : ''
  let description = value.description ? value.description : ''
  let address = value.address ? value.address : ''
  let email = value.email ? value.email : ''
  let phone = value.phone ? value.phone : ''
  let web = value.web ? value.web : ''

  return (
    <Segment clearing>
      <Button size={'mini'} icon negative floated='right' onClick={() => onClick(index)} >
        <Icon name='remove' />
      </Button>
      <Button size={'mini'} icon basic floated='right' onClick={() => onClickDown(index)}>
        <Icon name='chevron down' />
      </Button>
      <Button size={'mini'} icon basic floated='right' onClick={() => onClickUp(index)}>
        <Icon name='chevron up' />
      </Button>
      <Label >
        Instituto
      </Label><br /><br />
      <Form>
        <Form.Group widths='equal'>
          <Form.Input fluid label='Nombre' placeholder='Nombre'
            value={name} onChange={(e) => onChange(e.target.value, index, 'name')} />
        </Form.Group>
        <Form.Group widths='equal'>
          <Form.Input fluid label='Dirección' placeholder='Dirección'
            value={address} onChange={(e) => onChange(e.target.value, index, 'address')} />
        </Form.Group>
        <Form.Group widths='equal'>
          <Form.Input fluid label='Teléfono' placeholder='Teléfono'
            value={phone} onChange={(e) => onChange(e.target.value, index, 'phone')} />
          <Form.Input fluid label='Correo' placeholder='Correo'
            value={email} onChange={(e) => onChange(e.target.value, index, 'email')} />
          <Form.Input fluid label='Página web' placeholder='Página web'
            value={web} onChange={(e) => onChange(e.target.value, index, 'web')} />
        </Form.Group>
        <Form.Group widths='equal'>
          <Form.TextArea label='Descripción' placeholder='Descripción'
            value={description} onChange={(e) => onChange(e.target.value, index, 'description')} />
        </Form.Group>
      </Form>
    </Segment>
  )
}

export default Institute  