import React from 'react'
import Section from './Section'
import Title from './Title'

export default (props) => {

  const { page } = props

  let backgroundColor = '#fff'
  if(page.color){
    backgroundColor = page.color
  }

  let components = page.data.map((value, index) => {
    return <Title key={index} value={value}/>
  })

  return (
    <div id='magazine-pdf' style={{width: '360px', margin: 'auto', lineHeight: 'normal', 
      paddingTop: '15px', backgroundColor: backgroundColor}} >
      <Section value={{text: 'Contenido', margin: 15}} />
      {components}
      <div style={{height: '20px'}}></div>
    </div>
  )
}