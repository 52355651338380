import React from 'react'
import Section from './Section'
import Title from './Title'
import Paragraph from './Paragraph'
import TitleParagraph from './TitleParagraph'
import ImageWithCaption from './ImageWithCaption'
import TwoImageWithCaption from './TwoImageWithCaption'
import TwoImageWithTwoCaption from './TwoImageWithTwoCaption'
import TitleParagraphImage from './TitleParagraphImage'
import TitleImageParagraph from './TitleImageParagraph'
import Author from './Author'
import Ad from './Ad'
import Credits from './Credits'
import MagazineNumber from './MagazineNumber'

export default (props) => {

  const { page, selectedPage, evenPage, oddPage } = props

  let components = null

  let backgroundColor = '#fff'
  if(page.color){
    backgroundColor = page.color
  }

  if (page.data) {
    let firm = 0
    page.data.forEach((component, index) => {
      const { type } = component
      if(type==='paragraph'){
        firm = index
      }
    })

    components = page.data.map((value, index) => {
      const { type } = value
      if (type === 'section') {
        return <Section key={index} value={value} />
      }
      if (type === 'title') {
        return <Title key={index} value={value} />
      }
      if (type === 'paragraph') {
        return <Paragraph key={index} value={value} firm={firm === index} />
      }
      if (type === 'titleParagraph') {
        return <TitleParagraph key={index} value={value} />
      }
      if (type === 'imageWithCaption') {
        return <ImageWithCaption key={index} value={value} />
      }
      if (type === 'twoImageWithCaption') {
        return <TwoImageWithCaption key={index} value={value} />
      }
      if (type === 'twoImageWithTwoCaption') {
        return <TwoImageWithTwoCaption key={index} value={value} />
      }
      if (type === 'titleParagraphImage') {
        return <TitleParagraphImage key={index} value={value} />
      }
      if (type === 'titleImageParagraph') {
        return <TitleImageParagraph key={index} value={value} />
      }
      if (type === 'ad') {
        return <Ad key={index} value={value} />
      }
      if (type === 'author') {
        return <Author key={index} value={value} />
      }
      if (type === 'credits') {
        return <Credits key={index} value={value} />
      }
      return null
    })
  }

  return (
    <div id='magazine-pdf' style={{width: '360px', margin: 'auto', lineHeight: 'normal', 
      paddingTop: '15px', backgroundColor: backgroundColor}} >
      {components}
      <MagazineNumber indexPage={selectedPage} evenPage={evenPage} oddPage={oddPage} />
    </div>
  )
}