import React from 'react'
import { Segment, Form, Label, Button, Icon, Input } from 'semantic-ui-react'
import ImageUpload from '../components/ImageUpload'

const TwoImageWithTwoCaption = (props) => {

  const { value, index, onChange, onClick, onClickUp, onClickDown } = props

  let caption1 = value.caption1?value.caption1:''
  let caption2 = value.caption2?value.caption2:''
  let link1 = value.link1?value.link1:''
  let youtube1 = value.youtube1?value.youtube1:''
  let link2 = value.link2?value.link2:''
  let youtube2 = value.youtube2?value.youtube2:''
  let margin = value.margin?value.margin:''

  const onChangeImage1 = (value) => {
    onChange(value, index, 'image1')
  }

  const onChangeImage2 = (value) => {
    onChange(value, index, 'image2')
  }

  return (
    <Segment clearing>
      <Button size={'mini'} icon negative floated='right' onClick={()=>onClick(index)} >
        <Icon name='remove' />
      </Button>
      <Button size={'mini'}  icon basic floated='right' onClick={()=> onClickDown(index)}>
        <Icon name='chevron down' />
      </Button>
      <Button size={'mini'}  icon basic floated='right' onClick={()=> onClickUp(index)}>
        <Icon name='chevron up' />
      </Button>
      <Label >
        Dos imagenes con leyenda
      </Label><br/><br/>
      <Form>
        <Form.Group widths='equal'>
          <ImageUpload image={value.image1} onChange={onChangeImage1} />
          <ImageUpload image={value.image2} onChange={onChangeImage2} />
        </Form.Group>
        <Form.Group widths='equal'>
          <Form.Input fluid label='Enlace' placeholder='Enlace' 
            value={link1} onChange={(e) => onChange(e.target.value, index, 'link1')} />
          <Form.Input fluid label='YouTube (ID)' placeholder='YouTube (ID)' 
            value={youtube1} onChange={(e) => onChange(e.target.value, index, 'youtube1')} />
          <Form.Input fluid label='Enlace' placeholder='Enlace' 
            value={link2} onChange={(e) => onChange(e.target.value, index, 'link2')} />
          <Form.Input fluid label='YouTube (ID)' placeholder='YouTube (ID)' 
            value={youtube2} onChange={(e) => onChange(e.target.value, index, 'youtube2')} />
        </Form.Group>
        <Form.Group>
          <Form.Input label='Leyenda 1' placeholder='Leyenda 1' width={8}
            value={caption1} onChange={(e) => onChange(e.target.value, index, 'caption1')} />
          <Form.Input label='Leyenda 2' placeholder='Leyenda 2' width={6}
            value={caption2} onChange={(e) => onChange(e.target.value, index, 'caption2')} />
          <Form.Field width={2}>
            <label>Margen</label>
            <Input placeholder='Margen' 
              onChange={(e) => onChange(e.target.value, index, 'margin')} value={margin} />
          </Form.Field>
        </Form.Group>
      </Form>
    </Segment>
  )
}

export default TwoImageWithTwoCaption