import React, { useState, useEffect } from 'react';
import { Grid, Icon, Button, Header, Dimmer, Loader } from 'semantic-ui-react'
import { useHistory } from 'react-router-dom'
import MagazineMenu from '../../components/MagazineMenu'
import MagazineCard from './MagazineCard'
import { firestore } from '../../firebase-init'
import { useParams } from 'react-router-dom'

const ListMagazine = (props) => {

  const { dev } = useParams()
  let history = useHistory()
  const [state, setState] = useState({
    loading: true,
    magazines: []
  })

  const createMagazine = () => {
    const url = dev ? '/dev/magazine/create' : '/magazine/create'
    history.push(url)
  }

  useEffect(() => {
    const collection = dev ? 'test-magazine-pages' : 'magazine-pages'
    const listMagazines = firestore.collection(collection).doc('list-magazine')
    listMagazines.get().then(document => {
      let magazines = []
      if (document.exists) {
        const magazinesData = document.data()
        Object.keys(magazinesData).forEach((key) => {
          let magazine = magazinesData[key]
          magazine.key = key
          magazines.push(magazine)
        })
      }
      magazines.sort((a, b) => {
        const editionNameA = parseEdition(a.editionName)
        const editionNameB = parseEdition(b.editionName)
        if (editionNameA > editionNameB) {
          return -1
        }
        if (editionNameA < editionNameB) {
          return 1
        }
        return 0
      })
      setState({
        loading: false,
        magazines
      })
    })
  }, [dev])

  function parseEdition(edition) {
    const reg = /[0-9]/g
    const res = []
    while (true) {
      const match = reg.exec(edition)
      if (!match) {
        break
      }
      res.push(match[1] || match[0])
    }
    const editionStr = res.join('')
    const editionNumber = Number.parseInt(editionStr, 10)
    if (Number.isNaN(editionNumber)) {
      return 0
    }
    return editionNumber
  }

  return (
    <MagazineMenu>
      <Grid columns={1} padded>
        <Grid.Column>
          <Header as='h3' block>
            Revistas
          </Header>
        </Grid.Column>
      </Grid>
      <Grid columns={1} padded>
        <Grid.Column>
          <Button primary onClick={createMagazine} ><Icon name='add' /> Revista</Button>
        </Grid.Column>
      </Grid>
      <Grid columns={3} padded>
        <Dimmer active={state.loading} page >
          <Loader />
        </Dimmer>
        <List magazines={state.magazines} />
      </Grid>
    </MagazineMenu>
  )
}

const List = (props) => {

  const { magazines } = props
  return magazines.map((magazine) => {
    return <MagazineCard key={magazine.id} id={magazine.id}
      title={magazine.title} subtitle={magazine.subtitle}
      editionName={magazine.editionName} cover={magazine.cover}
      keyList={magazine.key} />
  })
}

export default ListMagazine