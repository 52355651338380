import React, {useState, useEffect} from 'react'
import { Segment, Form, Label, Button, Icon, Input } from 'semantic-ui-react'
import ImageUpload from '../components/ImageUpload'
import RichTextEditor from 'semantic-ui-react-rte'

const TitleImageParagraph = (props) => {

  const { value, index, onChange, onClick, onClickUp, onClickDown } = props

  let title = value.title?value.title:''
  let color = value.color?value.color:''
  let titleColor = value.titleColor?value.titleColor:''
  let paragraphColor = value.paragraphColor?value.paragraphColor:''
  let link = value.link?value.link:''
  let youtube = value.youtube?value.youtube:''
  let margin = value.margin?value.margin:''

  const onChangeImage = (value) => {
    onChange(value, index, 'image')
  }

  return (
    <Segment clearing>
      <Button size={'mini'} icon negative floated='right' onClick={()=>onClick(index)} >
        <Icon name='remove' />
      </Button>
      <Button size={'mini'}  icon basic floated='right' onClick={()=> onClickDown(index)}>
        <Icon name='chevron down' />
      </Button>
      <Button size={'mini'}  icon basic floated='right' onClick={()=> onClickUp(index)}>
        <Icon name='chevron up' />
      </Button>
      <Label >
        Título, imagen y párrafo
      </Label><br/><br/>
      <Form>
        <Form.Group widths='equal'>
          <Form.Input fluid label='Título' placeholder='Título' 
          value={title} onChange={(e) => onChange(e.target.value, index, 'title')} />
          <Form.Field width={4}>
            <label>Color de Fondo</label>
            <Input placeholder='Fondo' labelPosition='right' 
              label={<Label style={{backgroundColor: color, border: '1px solid rgba(34,36,38,.15)'}}>&nbsp;</Label>}
              onChange={(e) => onChange(e.target.value, index, 'color')} value={color} />
          </Form.Field>
          <Form.Field width={4}>
            <label>Color de Titulo</label>
            <Input placeholder='Titulo' labelPosition='right' 
              label={<Label style={{backgroundColor: titleColor, border: '1px solid rgba(34,36,38,.15)'}}>&nbsp;</Label>}
              onChange={(e) => onChange(e.target.value, index, 'titleColor')} value={titleColor} />
          </Form.Field>
          <Form.Field width={4}>
            <label>Color de Parrafo</label>
            <Input placeholder='Parrafo' labelPosition='right' 
              label={<Label style={{backgroundColor: paragraphColor, border: '1px solid rgba(34,36,38,.15)'}}>&nbsp;</Label>}
              onChange={(e) => onChange(e.target.value, index, 'paragraphColor')} value={paragraphColor} />
          </Form.Field>
          <Form.Field width={3}>
            <label>Margen</label>
            <Input placeholder='Margen' 
              onChange={(e) => onChange(e.target.value, index, 'margin')} value={margin} />
          </Form.Field>
        </Form.Group>
        <Form.Group widths='equal'>
          <ImageUpload image={value.image} onChange={onChangeImage} />
          <Form.Input fluid label='Enlace' placeholder='Enlace' 
            value={link} onChange={(e) => onChange(e.target.value, index, 'link')} />
          <Form.Input fluid label='YouTube (ID)' placeholder='YouTube (ID)' 
            value={youtube} onChange={(e) => onChange(e.target.value, index, 'youtube')} />
        </Form.Group>
        <Form.Group widths='equal'>
          <Rich text={value.paragraph} text2={value.paragraph2} onChange={onChange} index={index}  />
        </Form.Group>
      </Form>
    </Segment>
  )
}

export default TitleImageParagraph

const Rich = (props) => {

  const {text, text2, onChange, index} = props
  const [richText, setRichText] = useState(RichTextEditor.createEmptyValue())

  useEffect(() => {
    if(text2){
      setRichText(RichTextEditor.createValueFromString(text2, 'html'))
    }else{
      if(text){
        setRichText(RichTextEditor.createValueFromString(text, 'html'))
      }
    }
    // eslint-disable-next-line
  }, [text])

  const onChangeRich = (value) => {
    setRichText(value)
    onChange(value.toString('html'), index, 'paragraph2')
  }

  return (
    <RichTextEditor
      value={richText}
      onChange={onChangeRich}
    />
  )
}