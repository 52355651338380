import React from 'react'
import { Segment, Form, Label, Button, Icon, Input } from 'semantic-ui-react'
import ImageUpload from '../components/ImageUpload'

const ImageWithCaption = (props) => {

  const { value, index, onChange, onClick, onClickUp, onClickDown } = props

  let caption = value.caption?value.caption:''
  let link = value.link?value.link:''
  let youtube = value.youtube?value.youtube:''
  let margin = value.margin?value.margin:''

  const onChangeImage = (value) => {
    onChange(value, index, 'image')
  }

  return (
    <Segment clearing>
      <Button size={'mini'} icon negative floated='right' onClick={()=>onClick(index)} >
        <Icon name='remove' />
      </Button>
      <Button size={'mini'}  icon basic floated='right' onClick={()=> onClickDown(index)}>
        <Icon name='chevron down' />
      </Button>
      <Button size={'mini'}  icon basic floated='right' onClick={()=> onClickUp(index)}>
        <Icon name='chevron up' />
      </Button>
      <Label >
        Imagen con leyenda
      </Label><br/><br/>
      <Form>
        <Form.Group widths='equal'>
          <ImageUpload image={value.image} onChange={onChangeImage} />
          <Form.Input fluid label='Leyenda' placeholder='Leyenda' 
            value={caption} onChange={(e) => onChange(e.target.value, index, 'caption')} />
        </Form.Group>
        <Form.Group widths='equal'>
          <Form.Input fluid label='Enlace' placeholder='Enlace'  width={6}
            value={link} onChange={(e) => onChange(e.target.value, index, 'link')} />
          <Form.Input fluid label='YouTube (ID)' placeholder='YouTube (ID)'  width={6}
            value={youtube} onChange={(e) => onChange(e.target.value, index, 'youtube')} />
          <Form.Field width={3}>
            <label>Margen</label>
            <Input placeholder='Margen' 
              onChange={(e) => onChange(e.target.value, index, 'margin')} value={margin} />
          </Form.Field>
        </Form.Group>
      </Form>
    </Segment>
  )
}

export default ImageWithCaption