import React from 'react'
import { Segment, Label, Button, Icon } from 'semantic-ui-react'

const Logo = (props) => {

  const { index, onClick, onClickUp, onClickDown } = props

  return (
    <Segment clearing>
      <Button size={'mini'} icon negative floated='right' onClick={() => onClick(index)} >
        <Icon name='remove' />
      </Button>
      <Button size={'mini'} icon basic floated='right' onClick={() => onClickDown(index)}>
        <Icon name='chevron down' />
      </Button>
      <Button size={'mini'} icon basic floated='right' onClick={() => onClickUp(index)}>
        <Icon name='chevron up' />
      </Button>
      <Label >
        Logo
      </Label>
    </Segment>
  )
}

export default Logo