import React, { useState } from 'react'
import { Segment, Form, Label, Button, Icon, Grid, Input, Header, Checkbox } from 'semantic-ui-react'

const typeContent = [
  { text: 'Celular', value: 'phone' },
  { text: 'Correo', value: 'email' },
  { text: 'Página Web', value: 'web' },
  { text: 'Texto', value: 'text' }
]

const Credits = (props) => {

  const { value, index, onChange, onClick, onClickUp, onClickDown } = props
  const [selectedCreditsType, setSelectedCreditsType] = useState(null)

  const addContent = () => {
    
    if (!value.content) {
      value.content = []
    }

    let credit = {
      type: selectedCreditsType,
      text: ''
    }

    value.content.push(credit)

    setSelectedCreditsType(null)
    onChange(value.content, index, 'content')

  }

  const removeContent = (indexContent) => {     

    value.content.splice(indexContent, 1)

    onChange(value.content, index, 'content')

  }

  const onChangeContent = (newValue, indexContent, contentItem) => {    
    
    contentItem.text = newValue

    value.content[indexContent] = contentItem

    onChange(value.content, index, 'content')

  }

  let title = value.title?value.title:''
  let margin = value.margin?value.margin:''
  let titleBackgroundColor = value.titleBackgroundColor?value.titleBackgroundColor:''
  let titleColor = value.titleColor?value.titleColor:''
  let titleBold = value.titleBold? value.titleBold : false
  let elementBackgroundColor = value.elementBackgroundColor?value.elementBackgroundColor:''
  let elementColor = value.elementColor?value.elementColor:''
  let elementBold = value.elementBold? value.elementBold : false

  return (
    <Segment clearing>
      <Button size={'mini'} icon negative floated='right' onClick={() => onClick(index)} >
        <Icon name='remove' />
      </Button>
      <Button size={'mini'}  icon basic floated='right' onClick={()=> onClickDown(index)}>
        <Icon name='chevron down' />
      </Button>
      <Button size={'mini'}  icon basic floated='right' onClick={()=> onClickUp(index)}>
        <Icon name='chevron up' />
      </Button>
      <Label >
        Créditos
      </Label><br /><br />
      <Form>
        <Form.Group widths='equal'>
          <Form.Input fluid label='Título' placeholder='Título'
            value={title} onChange={(e) => onChange(e.target.value, index, 'title')} />
          <Form.Field width={4}>
            <label>Color de Fondo</label>
            <Input placeholder='Fondo' labelPosition='right' 
              label={<Label style={{backgroundColor: titleBackgroundColor, border: '1px solid rgba(34,36,38,.15)'}}>&nbsp;</Label>}
              onChange={(e) => onChange(e.target.value, index, 'titleBackgroundColor')} value={titleBackgroundColor} />
          </Form.Field>
          <Form.Field width={4}>
            <label>Color de Titulo</label>
            <Input placeholder='Titulo' labelPosition='right' 
              label={<Label style={{backgroundColor: titleColor, border: '1px solid rgba(34,36,38,.15)'}}>&nbsp;</Label>}
              onChange={(e) => onChange(e.target.value, index, 'titleColor')} value={titleColor} />
          </Form.Field>
          <Form.Field width={2}>
            <Checkbox label='Negrita' checked={titleBold} onClick={(event, data) => {onChange(data.checked, index, 'titleBold')}} />
          </Form.Field>
          <Form.Field width={3}>
            <label>Margen</label>
            <Input placeholder='Margen' 
              onChange={(e) => onChange(e.target.value, index, 'margin')} value={margin} />
          </Form.Field>
        </Form.Group>
      </Form>
      <Header as='h5'>Elementos</Header>
      <Form>
        <Form.Group widths='equal'>
         <Form.Field width={4}>
            <label>Color de Fondo</label>
            <Input placeholder='Fondo' labelPosition='right' 
              label={<Label style={{backgroundColor: elementBackgroundColor, border: '1px solid rgba(34,36,38,.15)'}}>&nbsp;</Label>}
              onChange={(e) => onChange(e.target.value, index, 'elementBackgroundColor')} value={elementBackgroundColor} />
          </Form.Field>
          <Form.Field width={4}>
            <label>Color de Elemento</label>
            <Input placeholder='Elemento' labelPosition='right' 
              label={<Label style={{backgroundColor: elementColor, border: '1px solid rgba(34,36,38,.15)'}}>&nbsp;</Label>}
              onChange={(e) => onChange(e.target.value, index, 'elementColor')} value={elementColor} />
          </Form.Field>
          <Form.Field width={2}>
            <Checkbox label='Negrita' checked={elementBold} onClick={(event, data) => {onChange(data.checked, index, 'elementBold')}} />
          </Form.Field>
        </Form.Group>
      </Form>
      <Segment.Group>
        <Content content={value.content} removeContent={removeContent} onChangeContent={onChangeContent} />
        <Segment >
          <Grid relaxed='very'>
            <Grid.Column floated='left' width={14}>
              <Form floated='left' width={16} >
                <Form.Select options={typeContent} placeholder='Tipo' value={selectedCreditsType} width={16}
                  onChange={(e, data) => { setSelectedCreditsType(data.value) }} inline label='Nuevo elemento'/>
              </Form>
            </Grid.Column>
            <Grid.Column floated='right'  width={2}>
              <Button secondary size={'mini'} icon floated='right' disabled={selectedCreditsType === null} onClick={addContent}>
                <Icon name='plus' />
              </Button>
            </Grid.Column>
          </Grid>
        </Segment>
      </Segment.Group>
    </Segment>
  )
}

const Content = (props) => {

  const { content, removeContent, onChangeContent } = props

  return content ? content.map((data, index) => {

    let labelType = typeContent.filter((type) => data.type === type.value).map((data) => data.text)

    let text = data.text?data.text:''

    return (
      <Segment key={index} >
        <Grid>
          <Grid.Column floated='left' width={14}>
            <Input placeholder='Valor' fluid
              value={text} width={7} onChange={(e) => onChangeContent(e.target.value, index, data)} label={labelType[0]}/>
          </Grid.Column>
          <Grid.Column floated='right' width={2}>
            <Button size={'mini'} icon negative floated='right' onClick={()=>removeContent(index)} >
              <Icon name='minus' />
            </Button>
          </Grid.Column>
        </Grid>
      </Segment>
    )
  }) : null
}

export default Credits