import React, { useState, useEffect } from 'react';
import { Grid, Icon, Segment, Button, Header, Image, Label, Item } from 'semantic-ui-react'
import { storage } from '../../firebase-init'
import { useHistory } from 'react-router-dom'
import { useParams } from 'react-router-dom'

const MagazineCard = (props) => {

  const { dev } = useParams()
  const { id, title, subtitle, editionName, cover, keyList } = props
  const [imageUrl, setImageUrl] = useState()
  let history = useHistory()

  useEffect(() => {
    let isCancelled = false
    if (cover) {
      storage.ref(cover).getDownloadURL().then(function (url) {
        if (!isCancelled) {
          setImageUrl(url)
        }
      })
    }
    return () => {
      isCancelled = true
    }
  }, [cover])

  const goMagazine = () => {
    const url = dev ? '/dev/magazine/' + id + '/' + keyList : '/magazine/' + id + '/' + keyList
    history.push(url)
  }

  return (
    <Grid.Column>
      <Segment style={{ minHeight: '330px' }} piled >
        <Grid columns={2} divided>
          <Grid.Row>
            <Grid.Column>
              <Image src={imageUrl} />
            </Grid.Column>
            <Grid.Column>
              <Button.Group size='mini' floated='right' >
                <Button icon primary onClick={goMagazine} >
                  <Icon name='edit' />
                </Button>
              </Button.Group>
              <Header as='h3'>
                {title}
                <Header.Subheader>
                  {subtitle}
                </Header.Subheader>
              </Header>
              <Item.Group>
                <Item>
                  <Item.Extra>
                    <Label>
                      <Icon name='newspaper' />
                      {editionName}
                    </Label>
                  </Item.Extra>
                </Item>
              </Item.Group>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    </Grid.Column>
  )
}

export default MagazineCard