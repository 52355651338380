import React from 'react';
import { Container, Menu, Icon } from 'semantic-ui-react'
import { useHistory } from "react-router-dom"
import { auth } from '../firebase-init'

const MagazineMenu = (props) => {

  const { children } = props
  const [visible, setVisible] = React.useState({
    width: '50px',
    titulo: '',
    revista: '',
    user: '',
    resources: '',
    videos: '',
    logout: '',
    icon: true
  })
  let history = useHistory()

  const onClick = ()=>{
    if(visible.icon){
      setVisible({
        width: '210px',
        titulo: 'Seguridad Minera',
        revista: 'Revistas',
        user: 'Usuarios',
        resources: 'Recursos',
        videos: 'Videos',
        logout: 'Cerrar Sesión',
        icon: false
      })
    }else{
      setVisible({
        width: '50px',
        titulo: '',
        revista: '',
        user: '',
        resources: '',
        videos: '',
        logout: '',
        icon: true
      })
    }
  }

  const goListMagazine = ()=>{
    history.push('/magazine/')
  }

  const goCreateUser = ()=>{
    history.push('/create-user/')
  }

  const goResources = ()=>{
    history.push('/resources/')
  }

  const goVideos = ()=>{
    history.push('/videos/')
  }

  const logout = async ()=>{
    await auth.signOut()
    window.location.href = "/"
  }

  return (
    <React.Fragment>
      <div style={{position: 'fixed', width: visible.width, top: 0, bottom: 0, left: 0, background: '#1B1C1D'}}>
        <Menu icon={visible.icon} vertical inverted >
          <Menu.Item onClick={onClick}>
            <Icon name='bars' /> {visible.titulo}
          </Menu.Item>
          <Menu.Item onClick={goListMagazine}>
            <Icon name='newspaper' /> {visible.revista}
          </Menu.Item>
          <Menu.Item onClick={goCreateUser}>
            <Icon name='user' /> {visible.user}
          </Menu.Item>
          <Menu.Item onClick={goResources}>
            <Icon name='file alternate' /> {visible.resources}
          </Menu.Item>
          <Menu.Item onClick={goVideos}>
            <Icon name='video play' /> {visible.videos}
          </Menu.Item>
          <Menu.Item onClick={logout}>
            <Icon name='sign-out' /> {visible.logout}
          </Menu.Item>
        </Menu>
      </div>
      <div style={{marginLeft: visible.width}}>
        <Container fluid>
          {children}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default MagazineMenu