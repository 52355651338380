import React, { useState, useEffect } from 'react';
import { Grid, Dimmer, Loader } from 'semantic-ui-react'
import { useParams, useHistory } from 'react-router-dom'
import { firestore, storage } from '../../firebase-init'
import MagazineMenu from '../../components/MagazineMenu'
import MagazineInformation from './MagazineInformation'
import MagazineListPage from './MagazineListPage'
import MagazinePage from './MagazinePage'
import moment from 'moment'

const Magazine = (props) => {

  let { key, keyList, dev } = useParams()
  let history = useHistory()
  const [state, setState] = useState({
    loading: true,
    magazine: null,
    selectedPageType: null,
    selectedPage: null,
    selectedPublicationType: null,
    selectedPostonType: null,
    published: false,
    openModalPublished: false
  })

  useEffect(() => {

    if (key === 'create') {
      setState({
        loading: false,
        magazine: { payment: false, priceMagazine: '0' },
        selectedPageType: null,
        selectedPage: null,
        selectedPublicationType: null,
        selectedPostonType: null,
        published: false,
        openModalPublished: false
      })
    } else {
      const collection = dev ? 'test-magazines' : 'magazines'
      const magazine = firestore.collection(collection).doc(key)
      let magazineData = {}
      let published = false

      magazine.get()
        .then(document => {
          if (document.exists) {
            magazineData = document.data()
            const collection = dev ? 'test-magazine-pages' : 'magazine-pages'
            const magazinePagesDoc = firestore.collection(collection).doc('list-magazine-publish')
            return magazinePagesDoc.get()
          }
        })
        .then(magazinePublish => {
          if (magazinePublish.exists) {
            let docMagaPub = magazinePublish.data()
            if (docMagaPub[keyList]) {
              published = true
            }
          }

          const collection = dev ? 'test-device-token' : 'device-token'
          const notificationsDoc = firestore.collection(collection).doc('notifications')
          return notificationsDoc.get()
        })
        .then(notificationsDoc => {
          let notifications = notificationsDoc.data()
          notifications['body'] = magazineData.editionName + ' - ' + magazineData.title;
          setState({
            loading: false,
            magazine: magazineData,
            selectedPageType: null,
            selectedPage: null,
            selectedPublicationType: null,
            selectedPostonType: null,
            published: published,
            openModalPublished: false,
            notifications: notifications
          })
        })
    }
  }, [key, dev, keyList])


  const saveMagazine = () => {
    setState({ ...state, loading: true })
    saveMagazineData().then((url) => {
      if (key === 'create') {
        history.replace(url)
      } else {
        setState({ ...state, loading: false })
      }
    })
  }

  //TBD: redirect y estado de publicado
  const publishMagazine = () => {

    setState({ ...state, loading: true, openModalPublished: false })
    publishMagazinePage().then(() => {
      setState({ ...state, loading: false, published: true, openModalPublished: false })
    })
  }

  //TBD: redirect y estado de publicado
  const despublishMagazine = () => {

    setState({ ...state, loading: true })
    unpublishMagazinePage().then(() => {
      setState({ ...state, loading: false, published: false })
    })
  }

  const saveMagazineData = async () => {
    const collection = dev ? 'test-magazines' : 'magazines'
    let keyMagazine = key === 'create' ? firestore.collection(collection).doc().id : key
    let documentMagazine = firestore.collection(collection).doc(keyMagazine)
    let magazine = state.magazine
    let pages = magazine.pages
    if (pages) {
      await Promise.all(
        pages.map(async (page) => {
          const { type, data } = page
          if (type === 'cover' || type === 'ad') {
            let { image } = data
            if (image && image.file) {
              const { file, path } = image
              const storageRef = storage.ref(path)
              await storageRef.put(file)
              delete image.file
            }
          }
          if (type === 'content' && data) {
            await Promise.all(
              data.map(async (title) => {
                if (title.image && title.image.file) {
                  let { image } = title
                  const { file, path } = image
                  const storageRef = storage.ref(path)
                  await storageRef.put(file)
                  delete image.file
                }
              })
            )
          }
          if (type === 'publication' && data) {
            await Promise.all(
              data.map(async (component) => {
                if (component.image && component.image.file) {
                  let { image } = component
                  const { file, path } = image
                  const storageRef = storage.ref(path)
                  await storageRef.put(file)
                  delete image.file
                }
                if (component.image1 && component.image1.file) {
                  let { image1 } = component
                  const { file, path } = image1
                  const storageRef = storage.ref(path)
                  await storageRef.put(file)
                  delete image1.file
                }
                if (component.image2 && component.image2.file) {
                  let { image2 } = component
                  const { file, path } = image2
                  const storageRef = storage.ref(path)
                  await storageRef.put(file)
                  delete image2.file
                }
              })
            )
          }
          if (type === 'poston' && data) {
            await Promise.all(
              data.map(async (component) => {
                if (component.image && component.image.file) {
                  let { image } = component
                  const { file, path } = image
                  const storageRef = storage.ref(path)
                  await storageRef.put(file)
                  delete image.file
                }
              })
            )
          }
        })
      )
    }

    if (pages) {
      pages.forEach((page) => {
        const { type, data } = page
        if (type === 'publication' && data) {
          data.forEach((component) => {
            if (component.type === 'paragraph') {
              let { text2 } = component
              if (text2) {
                text2 = text2.replaceAll('<p>', '')
                text2 = text2.replaceAll('</p>', '')
                component.text = text2
              }
              delete component.text2
            }
            if (component.type === 'titleParagraph') {
              let { text2 } = component
              if (text2) {
                text2 = text2.replaceAll('<p>', '')
                text2 = text2.replaceAll('</p>', '')
                component.text = text2
              }
              delete component.text2
            }
            if (component.type === 'titleParagraphImage') {
              let { paragraph2 } = component
              if (paragraph2) {
                paragraph2 = paragraph2.replaceAll('<p>', '')
                paragraph2 = paragraph2.replaceAll('</p>', '')
                component.paragraph = paragraph2
              }
              delete component.paragraph2
            }
            if (component.type === 'titleImageParagraph') {
              let { paragraph2 } = component
              if (paragraph2) {
                paragraph2 = paragraph2.replaceAll('<p>', '')
                paragraph2 = paragraph2.replaceAll('</p>', '')
                component.paragraph = paragraph2
              }
              delete component.paragraph2
            }
          })
        }
      })
    }

    //evaluate field null (payme)
    if (magazine.payment === undefined) {
      magazine.payment = false
    }
    if (magazine.priceMagazine === undefined) {
      magazine.priceMagazine = '0'
    }

    await documentMagazine.set(magazine)
    let keyListMagazine = await saveMagazinePage(keyMagazine, magazine)
    setState({
      ...state,
      magazine: magazine
    })
    const url = dev ? ('/dev/magazine/' + keyMagazine + '/' + keyListMagazine) :
      ('/magazine/' + keyMagazine + '/' + keyListMagazine)
    return url
  }

  async function saveMagazinePage(keyMagazine, data) {
    const collection = dev ? 'test-magazine-pages' : 'magazine-pages'
    const magazinePagesDoc = firestore.collection(collection).doc('list-magazine')

    let field = moment().format('YYYYMMDDHHmmss')

    if (keyList) {
      field = keyList
    }
    let value = {}

    let foundCover

    if (data.pages) {
      foundCover = data.pages.find(page => page.type === 'cover')
    }

    if (foundCover) {
      value.cover = foundCover.data.image.path
    }
    value.id = keyMagazine
    value.title = data.title
    value.subtitle = data.subtitle
    value.editionName = data.editionName
    value.editionDate = data.editionDate
    value.payment = data.payment
    value.priceMagazine = data.priceMagazine

    await magazinePagesDoc.set({ [field]: value }, { merge: true })

    return field
  }

  async function publishMagazinePage() {

    let data = state.magazine
    let keyMagazine = key
    const collection = dev ? 'test-magazine-pages' : 'magazine-pages'
    const magazinePagesDoc = firestore.collection(collection).doc('list-magazine-publish')

    let field = keyList

    let value = {}

    let foundCover

    if (data.pages) {
      foundCover = data.pages.find(page => page.type === 'cover')
    }

    if (foundCover) {
      value.cover = foundCover.data.image.path
    }
    value.id = keyMagazine
    value.title = data.title
    value.subtitle = data.subtitle
    value.editionName = data.editionName
    value.editionDate = data.editionDate
    //evaluate field null (payme)
    if (data.payment === undefined) {
      data.payment = false
    }
    if (data.priceMagazine === undefined) {
      data.priceMagazine = '0'
    }

    value.payment = data.payment
    value.priceMagazine = data.priceMagazine
    value.notifications = state.notifications

    await magazinePagesDoc.set({ [field]: value }, { merge: true })

    const url = dev ? ('/dev/magazine/' + keyMagazine + '/' + field) :
      ('/magazine/' + keyMagazine + '/' + field)
    return url
  }

  async function unpublishMagazinePage() {
    const collection = dev ? 'test-magazine-pages' : 'magazine-pages'
    const referMagazinePagePubl = firestore.collection(collection).doc('list-magazine-publish')
    let respMagazinePagePubl = await referMagazinePagePubl.get()
    if (respMagazinePagePubl.exists) {
      let docMagazinePagePubl = respMagazinePagePubl.data()
      delete docMagazinePagePubl[keyList]

      await referMagazinePagePubl.set(docMagazinePagePubl)
    }
    return
  }

  const onChangeState = (value) => {
    setState({
      ...state, ...value
    })
  }

  const openLoading = () => {
    setState({ ...state, loading: true })
  }

  const closeLoading = () => {
    setState({ ...state, loading: false })
  }

  return (
    <MagazineMenu>
      <Dimmer active={state.loading}>
        <Loader />
      </Dimmer>
      {
        state.magazine &&
        <MagazineData magazine={state.magazine}
          selectedPageType={state.selectedPageType}
          selectedPage={state.selectedPage}
          selectedPublicationType={state.selectedPublicationType}
          selectedPostonType={state.selectedPostonType}
          saveMagazine={saveMagazine}
          onChangeState={onChangeState}
          keyMagazine={key}
          publishMagazine={publishMagazine}
          published={state.published}
          despublishMagazine={despublishMagazine}
          openModalPublished={state.openModalPublished}
          notifications={state.notifications}
          openLoading={openLoading}
          closeLoading={closeLoading}
        />
      }
    </MagazineMenu>
  )
}

const MagazineData = (props) => {

  const { magazine, selectedPageType, selectedPage, selectedPublicationType,
    selectedPostonType, saveMagazine, onChangeState, keyMagazine, publishMagazine, 
    published, despublishMagazine, openModalPublished, notifications,
    openLoading, closeLoading } = props

  return (
    <React.Fragment>
      <MagazineInformation magazine={magazine} saveMagazine={saveMagazine} onChangeState={onChangeState} keyMagazine={keyMagazine} 
      published={published} publishMagazine={publishMagazine} despublishMagazine={despublishMagazine} 
      openModalPublished={openModalPublished} notifications={notifications} 
      openLoading={openLoading} closeLoading={closeLoading} />
      <MagazinePanel>
        <MagazineListPage magazine={magazine} selectedPageType={selectedPageType}
          selectedPage={selectedPage} onChangeState={onChangeState} />
        <MagazinePage magazine={magazine} selectedPublicationType={selectedPublicationType}
          selectedPage={selectedPage} onChangeState={onChangeState}
          selectedPostonType={selectedPostonType} />
      </MagazinePanel>
    </React.Fragment>
  )
}

const MagazinePanel = (props) => {

  const { children } = props

  return (
    <Grid padded divided >
      <Grid.Row>
        {children}
      </Grid.Row>
    </Grid>
  )
}

export default Magazine