// Firebase App (the core Firebase SDK) is always required and
// must be listed before other Firebase SDKs
import * as firebase from "firebase/app";

// Add the Firebase services that you want to use
import "firebase/firestore";
import "firebase/storage";
import "firebase/auth";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAj3saOzV_lPkxkFZjaYR2D0d-y3I2mxCM",
  authDomain: "gold-magazine.firebaseapp.com",
  databaseURL: "https://gold-magazine.firebaseio.com",
  projectId: "gold-magazine",
  storageBucket: "gold-magazine.appspot.com",
  messagingSenderId: "582371809502",
  appId: "1:582371809502:web:9be1142b8f1d9c2de44a4f",
  measurementId: "G-WQTJWMBMRT"
};

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);
const firestore = firebase.firestore();
const storage = firebase.storage()
const auth = firebase.auth()
const fieldValueDelete = firebase.firestore.FieldValue.delete()

export { app, firestore, storage, auth, fieldValueDelete }
