import React from 'react'
import { Segment, Form, Label, Button, Icon, Header, Grid, Input } from 'semantic-ui-react'

const InstituteDirectory = (props) => {

  const { value, index, onChange, onClick, onClickUp, onClickDown } = props

  let label = value.label ? value.label : ''

  let presidentLabel = value.president && value.president.label ? value.president.label : ''
  let presidentText = value.president && value.president.text ? value.president.text : ''

  let roleslessLabel = value.rolesless && value.rolesless.label ? value.rolesless.label : ''

  const addRol = () => {
    if (!value.roles) {
      value.roles = []
    }
    value.roles.push({})
    onChange(value.roles, index, 'roles')
  }

  const removeRol = (indexContent) => {
    value.roles.splice(indexContent, 1)
    onChange(value.roles, index, 'roles')
  }

  const onChangeRol = (newValue, indexContent, field) => {
    value.roles[indexContent][field] = newValue
    onChange(value.roles, index, 'roles')
  }

  const addRolesless = () => {
    if (!value.rolesless) {
      value.rolesless = {}
    }
    if (!value.rolesless.data) {
      value.rolesless.data = []
    }
    value.rolesless.data.push('')
    onChange(value.rolesless, index, 'rolesless')
  }

  const removeRolesless = (indexContent) => {
    value.rolesless.data.splice(indexContent, 1)
    onChange(value.rolesless, index, 'rolesless')
  }

  const onChangeRolesless = (newValue, indexContent) => {
    value.rolesless.data[indexContent] = newValue
    onChange(value.rolesless, index, 'rolesless')
  }

  const onChangeGroup = (newValue, index, group, field) => {
    let groupValue = value[group]
    if (!groupValue) {
      groupValue = {}
    }
    groupValue[field] = newValue
    onChange(groupValue, index, group)
  }

  return (
    <Segment clearing>
      <Button size={'mini'} icon negative floated='right' onClick={() => onClick(index)} >
        <Icon name='remove' />
      </Button>
      <Button size={'mini'} icon basic floated='right' onClick={() => onClickDown(index)}>
        <Icon name='chevron down' />
      </Button>
      <Button size={'mini'} icon basic floated='right' onClick={() => onClickUp(index)}>
        <Icon name='chevron up' />
      </Button>
      <Label >
        Directorio del instituto
      </Label><br /><br />
      <Form>
        <Form.Group widths='equal'>
          <Form.Input fluid label='Título' placeholder='Título'
            value={label} onChange={(e) => onChange(e.target.value, index, 'label')} />
        </Form.Group>
        <Form.Group widths='equal'>
          <Form.Input fluid label='Título presidente' placeholder='Título presidente'
            value={presidentLabel} onChange={(e) => onChangeGroup(e.target.value, index, 'president', 'label')} />
        </Form.Group>
        <Form.Group widths='equal'>
          <Form.Input fluid label='Presidente' placeholder='Presidente'
            value={presidentText} onChange={(e) => onChangeGroup(e.target.value, index, 'president', 'text')} />
        </Form.Group>
        <Form.Group widths='equal'>
          <Form.Input fluid label='Título directorio' placeholder='Título directorio'
            value={roleslessLabel} onChange={(e) => onChangeGroup(e.target.value, index, 'rolesless', 'label')} />
        </Form.Group>
      </Form>
      <Header as='h5'>Listado directorio</Header>
      <Segment.Group>
        <Rolesless rolesless={value.rolesless && value.rolesless.data} removeRolesless={removeRolesless} onChangeRolesless={onChangeRolesless} />
        <Segment >
          <Grid relaxed='very'>
            <Grid.Column floated='right' >
              <Button secondary size={'mini'} icon floated='right' onClick={addRolesless}>
                Agregar miembro del directorio <Icon name='plus' />
              </Button>
            </Grid.Column>
          </Grid>
        </Segment>
      </Segment.Group>
      <Header as='h5'>Plana gerencial</Header>
      <Segment.Group>
        <Roles roles={value.roles} removeRol={removeRol} onChangeRol={onChangeRol} />
        <Segment >
          <Grid relaxed='very'>
            <Grid.Column floated='right' >
              <Button secondary size={'mini'} icon floated='right' onClick={addRol}>
                Agregar gerente <Icon name='plus' />
              </Button>
            </Grid.Column>
          </Grid>
        </Segment>
      </Segment.Group>
    </Segment>
  )

}

const Roles = (props) => {

  const { roles, removeRol, onChangeRol } = props

  return roles ? roles.map((data, index) => {

    let text = data.text ? data.text : ''
    let label = data.label ? data.label : ''

    return (
      <Segment key={index} >
        <Grid>
          <Grid.Column floated='left' width={7}>
            <Input placeholder='Área' label='Área' fluid
              value={label} width={7} onChange={(e) => onChangeRol(e.target.value, index, 'label')} />
          </Grid.Column>
          <Grid.Column floated='left' width={7}>
            <Input placeholder='Gerente' label='Gerente' fluid
              value={text} width={7} onChange={(e) => onChangeRol(e.target.value, index, 'text')} />
          </Grid.Column>
          <Grid.Column floated='right' width={2}>
            <Button size={'mini'} icon negative floated='right' onClick={() => removeRol(index)} >
              <Icon name='minus' />
            </Button>
          </Grid.Column>
        </Grid>
      </Segment>
    )
  }) : null
}

const Rolesless = (props) => {

  const { rolesless, removeRolesless, onChangeRolesless } = props

  return rolesless ? rolesless.map((data, index) => {

    return (
      <Segment key={index} >
        <Grid>
          <Grid.Column floated='left' width={12}>
            <Input placeholder='Miembro del directorio' label='Miembro del directorio' fluid
              value={data} width={7} onChange={(e) => onChangeRolesless(e.target.value, index)} />
          </Grid.Column>
          <Grid.Column floated='right' width={4}>
            <Button size={'mini'} icon negative floated='right' onClick={() => removeRolesless(index)} >
              <Icon name='minus' />
            </Button>
          </Grid.Column>
        </Grid>
      </Segment>
    )
  }) : null
}

export default InstituteDirectory