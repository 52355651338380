import { firestore, storage, fieldValueDelete } from '../../../firebase-init'

const savePDF = async (path, file, dev, key, keyList) => {
  if(file){
    const storageRef = storage.ref(path)
    await storageRef.put(file)

    const collectionMagazinePage = dev ? 'test-magazine-pages' : 'magazine-pages'
    const magazinePagesDocPublish = firestore.collection(collectionMagazinePage).doc('list-magazine-publish')
    await magazinePagesDocPublish.set({ [keyList]: {pathPDF: path} }, { merge: true })
    const magazinePagesDoc = firestore.collection(collectionMagazinePage).doc('list-magazine')
    await magazinePagesDoc.set({ [keyList]: {pathPDF: path} }, { merge: true })
    
    const collectionMagazine = dev ? 'test-magazines' : 'magazines'
    const magazine = firestore.collection(collectionMagazine).doc(key)
    await magazine.set({ pathPDF: path }, { merge: true })
  }
}

const deletePDF = async (dev, key, keyList) => {
    const collectionMagazinePage = dev ? 'test-magazine-pages' : 'magazine-pages'
    const magazinePagesDocPublish = firestore.collection(collectionMagazinePage).doc('list-magazine-publish')
    await magazinePagesDocPublish.set({ [keyList]: {pathPDF: fieldValueDelete} }, { merge: true })
    const magazinePagesDoc = firestore.collection(collectionMagazinePage).doc('list-magazine')
    await magazinePagesDoc.set({ [keyList]: {pathPDF: fieldValueDelete} }, { merge: true })
    
    const collectionMagazine = dev ? 'test-magazines' : 'magazines'
    const magazine = firestore.collection(collectionMagazine).doc(key)
    await magazine.set({ pathPDF: fieldValueDelete }, { merge: true })
}

export {savePDF, deletePDF}