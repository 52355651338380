import React, {useState} from 'react';
import { Button, Form, Grid, Segment, Header } from 'semantic-ui-react'
import { auth } from '../../firebase-init'

const Login = () => {

  const [state, setState] = useState({
    user: '',
    password: ''
  })

  const onChangeText = (e,field) => {
    setState({...state, [field]: e.target.value})
  }

  const onLogin = () => {
    auth.signInWithEmailAndPassword(state.user, state.password).then(()=>{
      window.location.href = "/magazine"
    })
  }

  return (
    <Grid textAlign='center' style={{ height: '100vh' }} verticalAlign='middle'>
      <Grid.Column style={{ maxWidth: 450 }}>
        <Header as='h2' textAlign='center'>
          Seguridad Minera
        </Header>
        <Form size='large'>
          <Segment>
            <Form.Input fluid icon='user' iconPosition='left' placeholder='Usuario' 
              value={state.user} onChange={(e)=>onChangeText(e, 'user')} />
            <Form.Input
              fluid
              icon='lock'
              iconPosition='left'
              placeholder='Password'
              type='password'
              value={state.password} onChange={(e)=>onChangeText(e, 'password')}
            />
            <Button secondary fluid size='large' onClick={onLogin}>
              Login
            </Button>
          </Segment>
        </Form>
      </Grid.Column>
    </Grid>
  )
}

export default Login