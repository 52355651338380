import React, {useEffect, useState} from 'react'
import { storage } from '../../../../firebase-init'
import { Image } from 'semantic-ui-react'
import axios from 'axios'

export default (props) => {

  const { value } = props
  const { text, number, badge, image } = value
  const [imageTitle, setimageTitle] = useState()

  useEffect(() => {
    let isCancelled = false
    async function imageStorage(){
      if(image){
        const { path, file } = image
        if(file){
          let reader = new FileReader()
          reader.onload = function (e) {
            setimageTitle(e.target.result)
          }
          reader.readAsDataURL(file)
        }else{
          const url = await storage.ref(path).getDownloadURL()
          if(!isCancelled){
            const response = await axios.get('https://us-central1-gold-magazine.cloudfunctions.net/exportImageBase64', {
              params: {
                url: url
              }
            })
            const {base64} = response.data
            setimageTitle('data:image/jpeg;base64,'+base64)
          }
        }
      }
    }
    imageStorage()
    return () =>{
      isCancelled = true
    }
  }, [image])

  if(image){
    return (
      <div style={{display: 'flex', flexDirection: 'row', 
        margin: '0px 15px', padding: '15px 0px', borderBottom: '1px solid #f4f4f4'}}>
        <div style={{display: 'flex', flex: 1, flexDirection: 'row', marginRight: 5}}>
          <Number number={number} badge={badge} />
          <div style={{flex: 1}}>
            <div style={{color: '#4d4d4d', fontFamily: 'Swiss721BT-Roman' }}>
              {text}
            </div>
          </div>
        </div>
        <div style={{flex: 1, marginLeft: 5}}>
          <Image src={imageTitle} style={{ borderRadius: 5, borderWidth: 1, borderColor: '#949594' }} />
        </div>
      </div>
    )
  }

  return (
    <div style={{display: 'flex', flexDirection: 'row', 
      margin: '0px 15px', padding: '15px 0px', borderBottom: '1px solid #f4f4f4'}}>
      <Number number={number} badge={badge} />
      <div style={{flex: 1}}>
        <div style={{color: '#4d4d4d', fontFamily: 'Swiss721BT-Roman' }}>
          {text}
        </div>
      </div>
    </div>
  )
}

const Number = ({number, badge})=>{

  if(badge) {
    return (
      <div style={{marginRight: 5, marginLeft: -7.5, width: 25, height: 25,
        backgroundColor: '#008D36', textAlign: 'center', borderRadius: 15,
        paddingTop: '2px'}}>
        <div style={{ color: '#FFF', fontFamily: 'Swiss721BT-Roman' }}>{number}</div>
      </div>
    )
  }

  return (
    <div style={{marginRight: 5, marginLeft: -7.5, width: 25, height: 25,textAlign: 'center'}}>
      <div style={{ color: '#008D36', fontFamily: 'Swiss721BT-Roman' }}>{number}</div>
    </div>
  )

}