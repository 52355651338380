import React, {useState} from 'react'
import { Segment, Button, Modal } from 'semantic-ui-react'
import PageTitle from '../components/PageTitle'
import ContentItem from './ContentItem'
import ContentView from '../ContentView'

const Content = (props) => {

  const { page, selectedPage, onChangePage } = props
  const [open, setOpen] = useState(false)

  let components = null

  const onChangeMagazinePage = (value, field) => {
    page[field] = value
    onChangePage(selectedPage, page)
  }

  const onClickAddDataPage = () => {
    if(!page.data){
      page.data = []
    }
    page.data.push({ text: '', number: '', page: 1 })
    onChangePage(selectedPage, page)
  }

  const onClickDeleteDataPage = (index) => {
    page.data.splice(index, 1)
    onChangePage(selectedPage, page)
  }

  const onChangeMagazinePageData = (value, field, index) => {
    page.data[index][field] = value
    onChangePage(selectedPage, page)
  }

  const onClickUp = (key) => {
    let size = page.data.length
    let position = Number(key)-1
    if(position!==-1){
      let data = []
      let component = page.data[key]
      page.data.splice(key, 1)
      for (var index = 0; index < size; index++) {
        if(index === position){
          data.push(component)
        }else{
          data.push(page.data.shift())
        }
      }
      page.data = data
      onChangePage(selectedPage, page)
    }
  }

  const onClickDown = (key) => {
    let size = page.data.length
    let position = Number(key)+1
    if(position!==size){
      let data = []
      let component = page.data[key]
      page.data.splice(key, 1)
      for (var index = 0; index < size; index++) {
        if(index === position){
          data.push(component)
        }else{
          data.push(page.data.shift())
        }
      }
      page.data = data
      onChangePage(selectedPage, page)
    }
  }

  if (page.data) {
    components = page.data.map((value, index) => {
      const { number, page: pageNumber, text, badge, image } = value
      return <ContentItem key={index}
        number={number} pageNumber={pageNumber}
        text={text} badge={badge}
        image={image} index={index}
        onChange={onChangeMagazinePageData}
        onClickDeleteDataPage={onClickDeleteDataPage}
        onClickUp={onClickUp}
        onClickDown={onClickDown} />
    })
  }

  return (
    <React.Fragment>
      <Segment>
        Información principal del Indice<br/><br/>
        <PageTitle value={page.title} onChange={onChangeMagazinePage} />
      </Segment>
      {components}
      <Button style={{marginBottom: '1em'}} floated='right' secondary onClick={onClickAddDataPage}>Agregar</Button>
      <Modal
        size='tiny'
        open={open}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
      >
        <Modal.Header>Previsualizar</Modal.Header>
        <Modal.Content style={{backgroundColor: '#59514e'}}>
          <ContentView page={page}/>
        </Modal.Content>
      </Modal>
    </React.Fragment>
  )
}

export default Content