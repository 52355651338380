import React from 'react'
import { Segment, Form, Button } from 'semantic-ui-react'
import PageTitle from '../components/PageTitle'
import Logo from './Logo'
import LabelText from './LabelText'
import Institute from './Institute'
import Editorial from './Editorial'
import InstituteDirectory from './InstituteDirectory'

const Poston = (props) => {

  const { page, selectedPage, onChangePage, selectedPostonType, onChangeSelectedPostonType } = props

  const typeComponent = [
    { text: 'Logo', value: 'logo' },
    { text: 'Instituto', value: 'institute' },
    { text: 'Directorio del instituto', value: 'institute-directory' },
    { text: 'Compañia', value: 'companies' },
    { text: 'Socios', value: 'partners' },
    { text: 'Editorial', value: 'editorial' }
  ]

  const onAddComponent = () => {
    if (!page.data) {
      page.data = []
    }
    page.data.push({
      type: selectedPostonType
    })
    onChangePage(selectedPage, page)
    onChangeSelectedPostonType(null)
  }

  const onChangeComponent = (value, index, field) => {
    page.data[index][field] = value
    onChangePage(selectedPage, page)
  }

  const onClickRemove = (index) => {
    page.data.splice(index, 1)
    onChangePage(selectedPage, page)
  }

  const onChangeMagazinePage = (value, field) => {
    page[field] = value
    onChangePage(selectedPage, page)
  }

  const onClickUp = (key) => {
    let size = page.data.length
    let position = Number(key) - 1
    if (position !== -1) {
      let data = []
      let component = page.data[key]
      page.data.splice(key, 1)
      for (var index = 0; index < size; index++) {
        if (index === position) {
          data.push(component)
        } else {
          data.push(page.data.shift())
        }
      }
      page.data = data
      onChangePage(selectedPage, page)
    }
  }

  const onClickDown = (key) => {
    let size = page.data.length
    let position = Number(key) + 1
    if (position !== size) {
      let data = []
      let component = page.data[key]
      page.data.splice(key, 1)
      for (var index = 0; index < size; index++) {
        if (index === position) {
          data.push(component)
        } else {
          data.push(page.data.shift())
        }
      }
      page.data = data
      onChangePage(selectedPage, page)
    }
  }

  let components = null

  if (page.data) {
    components = page.data.map((value, index) => {
      const { type } = value
      if (type === 'logo') {
        return <Logo key={index} page={selectedPage} index={index} value={value}
          onChange={onChangeComponent} onClick={onClickRemove} onClickUp={onClickUp} onClickDown={onClickDown} />
      }
      if (type === 'companies') {
        return <LabelText key={index} page={selectedPage} value={value} index={index}
          onChange={onChangeComponent} onClick={onClickRemove} title='Compañia'
          onClickUp={onClickUp} onClickDown={onClickDown} rows={14}/>
      }
      if (type === 'partners') {
        return <LabelText key={index} page={selectedPage} value={value} index={index}
          onChange={onChangeComponent} onClick={onClickRemove} title='Socios'
          onClickUp={onClickUp} onClickDown={onClickDown} rows={4}/>
      }
      if (type === 'institute') {
        return <Institute key={index} page={selectedPage} value={value} index={index}
          onChange={onChangeComponent} onClick={onClickRemove}
          onClickUp={onClickUp} onClickDown={onClickDown} />
      }
      if (type === 'editorial') {
        return <Editorial key={index} page={selectedPage} value={value} index={index}
          onChange={onChangeComponent} onClick={onClickRemove}
          onClickUp={onClickUp} onClickDown={onClickDown} />
      }
      if (type === 'institute-directory') {
        return <InstituteDirectory key={index} page={selectedPage} value={value} index={index}
          onChange={onChangeComponent} onClick={onClickRemove}
          onClickUp={onClickUp} onClickDown={onClickDown} />
      }
      return <React.Fragment key={index} />
    })
  }

  return (
    <React.Fragment>
      <Segment>
        Información principal del Poston<br /><br />
        <PageTitle value={page.title} onChange={onChangeMagazinePage} />
      </Segment>
      {components}
      <Form>
        <Form.Group widths='equal'>
          <Form.Select options={typeComponent} placeholder='Tipo'
            value={selectedPostonType}
            onChange={(e, data) => { onChangeSelectedPostonType(data.value) }}
          />
          <Button secondary disabled={selectedPostonType === null} onClick={onAddComponent} >Agregar</Button>
        </Form.Group>
      </Form>
    </React.Fragment>
  )
}

export default Poston