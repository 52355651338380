import React, {useState} from 'react'
import { Segment, Form, Modal } from 'semantic-ui-react'
import ImageUpload from '../components/ImageUpload'
import PageTitle from '../components/PageTitle'
import CoverView from '../CoverView'

const Cover = (props) => {

  const { page, selectedPage, onChangePage } = props
  const [open, setOpen] = useState(false)

  const onChangeMagazinePage = (value, field) => {
    page[field] = value
    onChangePage(selectedPage, page)
  }

  const onChangeImage = (value) => {
    page.data.image = value
    onChangePage(selectedPage, page)
  }

  let image = page.data ? page.data.image : page.data

  return (
    <React.Fragment>
    <Segment>
      Información principal de la Portada<br/><br/>
      <PageTitle value={page.title} onChange={onChangeMagazinePage} />
      <Form>
        <ImageUpload image={image} onChange={onChangeImage} />
      </Form>
    </Segment>
    <Modal
      size='tiny'
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
    >
      <Modal.Header>Previsualizar</Modal.Header>
      <Modal.Content style={{backgroundColor: '#59514e'}}>
        <CoverView page={page} />
      </Modal.Content>
    </Modal>
    </React.Fragment>
  )
}

export default Cover