import React from 'react'

export default (props) => {
  const {value, firm} = props
  const { text, text2, margin, paragraphColor } = value

  let marginBottom = margin?Number.parseInt(margin): 10
  let paragraphColorStyle = paragraphColor?paragraphColor: '#000000'
  const firmText = firm? ' <span class="firm-magazine">SM</span>' : ''
  
  let style = {
    margin: '0px 20px', 
    paddingBottom: marginBottom+'px',
    color: paragraphColorStyle,
    textAlign: 'justify',
    fontSize: '16px', 
    fontFamily: 'Swiss721BT-Roman'
  }

  let textHtml = text
  if(text2){
    textHtml = text2
  }
  textHtml = textHtml.replaceAll('<p>','')
  textHtml = textHtml.replaceAll('</p>','')

  return (
    <div style={style} dangerouslySetInnerHTML={{ __html: textHtml+firmText }} />
  )

}