import React from 'react'
import { Form, Icon, Input, Label } from 'semantic-ui-react'

const Information = (props) => {

  const { page, onChange } = props

  const title = page.title? page.title : ''
  const color = page.color? page.color : ''
  const bookmark = page.bookmark? page.bookmark : false

  return (
    <Form>
      <Form.Group>
      	<Form.Checkbox label={{ children: <Icon name='bookmark' /> }} checked={bookmark} 
          onClick={(event, data) => {onChange(data.checked, 'bookmark')}} width={1} />
        <Form.Input label='Título' placeholder='Título' fluid
          onChange={(e) => onChange(e.target.value, 'title')} value={title} width={9} />
        <Form.Field width={6}>
          <label>Color de Fondo</label>
          <Input placeholder='Color de Fondo' labelPosition='right' 
            label={<Label style={{backgroundColor: color, border: '1px solid rgba(34,36,38,.15)'}}>&nbsp;</Label>}
            onChange={(e) => onChange(e.target.value, 'color')} value={color} />
        </Form.Field>
      </Form.Group>
    </Form>
  )
}

export default Information