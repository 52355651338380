import React, { useState, useEffect } from 'react'
import { storage } from '../../../../firebase-init'
import axios from 'axios'

export default (props) => {
  const {value} = props
  const { caption, image1, image2, margin } = value
  const [ srcImage1, setSrcImage1 ] = useState()
  const [ srcImage2, setSrcImage2 ] = useState()
  
  const marginBottom = margin?Number.parseInt(margin): 15
  const captionText = caption? caption : ''
  
  let style = {
    margin: '0px 20px', 
    paddingBottom: marginBottom+'px',
    textAlign: 'justify',
    fontSize: '14px', 
    fontFamily: 'Swiss721BT-RomanCondensed'
  }

  useEffect(() => {
    if(image1){
      const {file, path} = image1
      if(file){
        let reader = new FileReader()
        reader.onload = function (e) {
          setSrcImage1(e.target.result)
        }
        reader.readAsDataURL(file)
      }else if(path){
        storage.ref(path).getDownloadURL().then(function (url) {
          axios.get('https://us-central1-gold-magazine.cloudfunctions.net/exportImageBase64', {
            params: {
              url: url
            }
          }).then((response)=>{
            const {base64} = response.data
            setSrcImage1('data:image/jpeg;base64,'+base64)
          })
        })
      }
    }else{
      setSrcImage1(null)
    }
  }, [image1])

  useEffect(() => {
    if(image2){
      const {file, path} = image2
      if(file){
        let reader = new FileReader()
        reader.onload = function (e) {
          setSrcImage2(e.target.result)
        }
        reader.readAsDataURL(file)
      }else if(path){
        storage.ref(path).getDownloadURL().then(function (url) {
          axios.get('https://us-central1-gold-magazine.cloudfunctions.net/exportImageBase64', {
            params: {
              url: url
            }
          }).then((response)=>{
            const {base64} = response.data
            setSrcImage2('data:image/jpeg;base64,'+base64)
          })
        })
      }
    }else{
      setSrcImage2(null)
    }
  }, [image2])

  let img1Component = null
  if(srcImage1){
    img1Component = <img style={{width: '100%'}} src={srcImage1} alt='' />
  }

  let img2Component = null
  if(srcImage2){
    img2Component = <img style={{width: '100%'}} src={srcImage2} alt='' />
  }

  return (
    <div style={style} >
      <div style={{display: 'flex'}} >
        <div style={{flex: '1', marginRight: '5px'}}>
          {img1Component}
        </div>
        <div style={{flex: '1', marginLeft: '5px'}}>
          {img2Component} 
        </div>
      </div>
      {captionText}
    </div>
  )

}