import React from 'react'
import { Segment, Form, Label, Button, Icon, Checkbox, Grid, Input } from 'semantic-ui-react'

const Title = (props) => {

  const { value, index, onChangeCustomTitle, onChange, onClick, onClickUp, onClickDown } = props

  const typeTypography = [
    { text: 'Normal', value: 'text' },
    { text: 'Título 1', value: 'H1' },
    { text: 'Título 2', value: 'H2' },
    { text: 'Título 3', value: 'H3' }
  ]

  const typeCustom = [
    { text: 'Titulo', value: 'title' },
    { text: 'Antetítulo', value: 'preTitle' },
    { text: 'Subtítulo', value: 'subTitle' }
  ]

  const onChangeTypography = (e, data) => {
    onChange(data.value, index, 'typography')
  }

  const onChangeCustom = (e, data) => {
    let value = data.value
    if(value === 'title'){
      onChangeCustomTitle('H1', '#0e3d86', false, '10', index)
    }
    if(value === 'preTitle'){
      onChangeCustomTitle('H3', '#f07d00', false, '5', index)
    }
    if(value === 'subTitle'){
      onChangeCustomTitle('H3', '#0e3d86', false, '10', index)
    }
  }

  let text = value.text?value.text:''
  let color = value.color?value.color:''

  let disabledSize = true
  if(value.typography && value.typography === 'text'){
    disabledSize = false
  }

  let size = value.size?value.size:''

  let bold = value.bold? value.bold : false

  let margin = value.margin?value.margin:''

  return (
    <Segment clearing>
      <Button size={'mini'} icon negative floated='right' onClick={()=>onClick(index)} >
        <Icon name='remove' />
      </Button>
      <Button size={'mini'}  icon basic floated='right' onClick={()=> onClickDown(index)}>
        <Icon name='chevron down' />
      </Button>
      <Button size={'mini'}  icon basic floated='right' onClick={()=> onClickUp(index)}>
        <Icon name='chevron up' />
      </Button>
      <Grid >
        <Grid.Row>
          <Grid.Column width={4} style={{paddingLeft: '0'}}>
            <Label >
              Titulo
            </Label>
          </Grid.Column>
          <Grid.Column width={4}>
            <Form.Select fluid placeholder='Personalizado' options={typeCustom} onChange={onChangeCustom}/>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Form>
        <Form.Group>
          <Form.Input fluid label='Texto' placeholder='Texto' width={14}
          value={text} onChange={(e) => onChange(e.target.value, index, 'text')} />
          <Form.Field width={3}>
            <label>Margen</label>
            <Input placeholder='Margen' 
              onChange={(e) => onChange(e.target.value, index, 'margin')} value={margin} />
          </Form.Field>
          <Form.Field width={2}>
            <Checkbox label='Negrita' checked={bold} onClick={(event, data) => {onChange(data.checked, index, 'bold')}} />
          </Form.Field>
        </Form.Group>
        <Form.Group widths='equal'>
          <Form.Input fluid label='Color' placeholder='Color' 
          value={color} onChange={(e) => onChange(e.target.value, index, 'color')} />
          <Form.Select fluid label='Tipo' placeholder='Tipo' options={typeTypography} 
          value={value.typography} onChange={onChangeTypography}/>
          <Form.Input fluid label='Tamaño' placeholder='Tamaño' disabled={disabledSize} 
          value={size} pattern="[0-9]*" onChange={(e, data)=>{ onChange((data.value ? parseInt(data.value) : 0), index, 'size') }}/>
        </Form.Group>
      </Form>
    </Segment>
  )
}

export default Title