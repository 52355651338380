import React, { useState } from 'react';
import { Grid, Button, Header, Form, Message} from 'semantic-ui-react'
import MagazineMenu from '../../components/MagazineMenu'
import axios from 'axios'

const CreateUser = () => {

  const [state, setState] = useState({
    loading: true,
    user: '',
    password: '',
    message: ''
  })

  const onCreateUser = () => {
    axios.get('https://us-central1-gold-magazine.cloudfunctions.net/registerUser', {
      params: {
        user: state.user,
        password: state.password
      }
    })
    .then(function (response) {
      const {status} = response.data
      const user = state.user
      if(status === 1){
        setState({...state,
          user: '',
          password: '',
          message: 'El usuario ' + user + ' se creo correctamente'
        })
      }else{
        setState({...state,
          user: '',
          password: '',
          message: 'Error: El usuario ' + user + ' ya existe o intente mas tarde'
        })
      }
    })
    .catch(function (error) {
      console.log(error)
    })
  }

  return (
    <MagazineMenu>
      <Grid columns={1} padded>
        <Grid.Column>
          <Header as='h3' block>
            Crear Usuario
          </Header>
        </Grid.Column>
      </Grid>
      <Grid columns={2} padded>
        <Grid.Column>
          <Form>
            <Form.Field>
              <label>Email</label>
              <input placeholder='Email' value={state.user} 
                onChange={(e) => setState({...state, user: e.target.value})} />
            </Form.Field>
            <Form.Field>
              <label>Password</label>
              <input placeholder='Password' value={state.password} 
                onChange={(e) => setState({...state, password: e.target.value})} />
            </Form.Field>
            <Button primary onClick={onCreateUser}>Crear</Button>
            <Message>
              <p>
                {state.message}
              </p>
            </Message>
          </Form>
        </Grid.Column>
      </Grid>
    </MagazineMenu>
  )
}

export default CreateUser