import React from 'react'
import { Segment, Form, Label, Button, Icon } from 'semantic-ui-react'

const LabelText = (props) => {

  const { value, index, onChange, onClick, title, onClickUp, onClickDown, rows } = props

  let text = value.text ? value.text : ''
  let label = value.label ? value.label : ''

  return (
    <Segment clearing>
      <Button size={'mini'} icon negative floated='right' onClick={() => onClick(index)} >
        <Icon name='remove' />
      </Button>
      <Button size={'mini'} icon basic floated='right' onClick={() => onClickDown(index)}>
        <Icon name='chevron down' />
      </Button>
      <Button size={'mini'} icon basic floated='right' onClick={() => onClickUp(index)}>
        <Icon name='chevron up' />
      </Button>
      <Label >
        {title}
      </Label><br /><br />
      <Form>
        <Form.Group widths='equal'>
          <Form.Input fluid label='Título' placeholder='Título'
            value={label} onChange={(e) => onChange(e.target.value, index, 'label')} />
        </Form.Group>
        <Form.Group widths='equal'>
          <Form.TextArea label={title} placeholder={title} value={text} rows={rows}
            onChange={(e) => onChange(e.target.value, index, 'text')} />
        </Form.Group>
      </Form>
    </Segment>
  )
}

export default LabelText