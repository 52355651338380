import React from 'react';
import { Form, Button, Icon } from 'semantic-ui-react'
import { InputFile } from 'semantic-ui-react-input-file'
import { useParams } from 'react-router-dom'

const ImageUpload = (props) => {

  let { key } = useParams()
  const { label, image, onChange } = props

  const handleUpload = (event) => {

    let imageUpload = event.target.files[0]

    if(imageUpload){
      let imageTool = new Image()
      imageTool.src = URL.createObjectURL(imageUpload)
      imageTool.onload = function () {
        let value = {
          path: key + '/' + imageUpload.name,
          metadata: imageUpload.type,
          width: imageTool.width,
          height: imageTool.height,
          file: imageUpload
        }
        onChange(value)
      }
    }else{
      onChange(image)
    }
  }

  const changePath = (path) => {
    if(!path){
      return 'Seleccionar Imagen'
    }
    let index = path.lastIndexOf("/")
    return path.substring(index + 1)
  }

  let labelPath = image?changePath(image.path): 'Seleccionar Imagen'

  return (
    <Form.Field>
      <label>{label?label:'Imagen'}</label>
      <div className="field">
        <InputFile
          button={{ primary: true, label: labelPath }}
          input={{
            onChange: handleUpload,
            accept: "image/png, image/jpeg, image/gif"
          }}
        />
        <Button size={'mini'} icon onClick={()=> onChange(null)} >
          <Icon name='remove' />
        </Button>
      </div>
    </Form.Field>
  )
}

export default ImageUpload