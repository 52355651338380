import React from 'react'

export default (props) => {
  const {value} = props
  const { title, text, text2, color, titleColor, paragraphColor, margin, titleBold, paragraphBold } = value

  let backgroundColor = color?color: '#45904A'
  let marginBottom = margin?Number.parseInt(margin): 15
  const titleColorStyle = titleColor?titleColor: '#FFFFFF'
  const paragraphColorStyle = paragraphColor?paragraphColor: '#FFFFFF'
  const firmText = ' <span class="firm-magazine">SM</span>'

  let style = {
    paddingBottom: marginBottom+'px'
  }

  let style2 = {
    margin: '0px 20px', 
    padding: '10px',
    backgroundColor: backgroundColor,
  }

  let style3 = {
    color: titleColorStyle, 
    fontSize: '21px',
    fontFamily: 'Delicious-Heavy',
  }
  if(titleBold){
    style3['fontWeight'] = 'bold'
  }

  let style4 = {
    color: paragraphColorStyle,
    textAlign: 'justify', 
    fontSize: '16px',
    fontFamily: 'Swiss721BT-Roman',
  }
  if(paragraphBold){
    style4['fontWeight'] = 'bold'
  }

  let textHtml = text
  if(text2){
    textHtml = text2
  }
  textHtml = textHtml?textHtml.replaceAll('<p>','') : ''
  textHtml = textHtml?textHtml.replaceAll('</p>','') : ''

  return (
    <div style={style} >
      <div style={style2} >
        <div style={style3}>{title}</div>
        <div style={style4} dangerouslySetInnerHTML={{ __html: textHtml+firmText }} />
      </div>
    </div>
  )

}