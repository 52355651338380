import React, { useState, useEffect } from 'react';
import { Grid, Button, Header, Form, Icon, Segment, Modal} from 'semantic-ui-react'
import MagazineMenu from '../../components/MagazineMenu'
import { firestore } from '../../firebase-init'

const Resources = () => {

  const [state, setState] = useState([])
  const [open, setOpen] = useState(false)

  useEffect(()=> {
    const documentResources = firestore.collection('parameters').doc('resources')
    documentResources.get().then((document)=>{
      if(document.exists){
        const {list} = document.data()
        setState(list)
      }
    })
  },[])

  const onAddResourceGroup = () => {
    state.unshift( {
      name: '', resources: [{
        name: '', link: ''
      }]
    })
    setState([...state])
  }

  const onDeleteResourceGroup = (index) => {
    state.splice(index, 1)
    setState([...state])
  }

  const onClickUpResourceGroup = (index) => {
    const oldPosition = Number(index)
    const position = Number(index)-1
    if(position!==-1){
      const element = state.splice(oldPosition, 1)[0]
      state.splice(position, 0, element)
      setState([...state])
    }
  }

  const onClickDownResourceGroup = (index) => {
    const oldPosition = Number(index)
    const position = Number(index)+1
    if(position!==state.length){
      const element = state.splice(oldPosition, 1)[0]
      state.splice(position, 0, element)
      setState([...state])
    }
  }

  const onChangeResourceGroup = (index, key, value) => {
    state[index][key] = value
    setState([...state])
  }

  const onSaveResources = async () => {
    const documentResources = firestore.collection('parameters').doc('resources')
    await documentResources.set({list: state})
    setOpen(true)
  }

  const ListResourceGroup = state.map((resourceGroup, index)=> {
    return <ResourceGroup key={index} resourceGroup={resourceGroup} 
      onChange={(key, value)=>onChangeResourceGroup(index, key, value)}
      onDelete={()=> onDeleteResourceGroup(index)}
      onClickDown={()=> onClickDownResourceGroup(index)}
      onClickUp={()=> onClickUpResourceGroup(index)} />
  })

  return (
    <MagazineMenu>
      <Grid columns={1} padded>
        <Grid.Column>
          <Header as='h3' block>
            Recursos
          </Header>
          <Form>
            <Button secondary onClick={onAddResourceGroup}>Agregar Lista</Button>
            <Button primary onClick={onSaveResources}>Guardar Recurso</Button>
          </Form>
        </Grid.Column>
      </Grid>
      <Grid columns={1} padded >
        <Grid.Column 
          style={{ position: 'fixed', top: '120px', bottom: '0', overflowY: 'auto', width: '95%' }}>
          {ListResourceGroup}
        </Grid.Column>
      </Grid>
      <ModalConfirm open={open} onClose={()=> setOpen(false)} />
    </MagazineMenu>
  )
}

export default Resources

const ResourceGroup = ({resourceGroup, onDelete, onClickDown, onClickUp, onChange}) => {

  const {name, resources} = resourceGroup

  const onChangeResource = (index, key, value) => {
    resources[index][key] = value
    onChange('resources', resources)
  }

  const onDeleteResource = (index) => {
    resources.splice(index, 1)
    onChange('resources', resources)
  }

  const onAddResource = () => {
    resources.push({name: '', link: ''})
    onChange('resources', resources)
  }

  const ListResource = resources.map((resource, index) => {
    return <Resource key={index} resource={resource} 
      onChange={(key, value)=>onChangeResource(index, key, value)}
      onDelete={()=>onDeleteResource(index)} />
  })

  return (
    <Segment clearing>
      <Button size={'mini'} icon negative floated='right' onClick={onDelete} >
        <Icon name='remove' />
      </Button>
      <Button size={'mini'}  icon basic floated='right' onClick={onClickDown}>
        <Icon name='chevron down' />
      </Button>
      <Button size={'mini'}  icon basic floated='right' onClick={onClickUp}>
        <Icon name='chevron up' />
      </Button>
      <br/><br/>
      <Form>
        <Form.Group widths='equal'>
          <Form.Input placeholder='Nombre' value={name} onChange={(e)=>onChange('name', e.target.value)} />
        </Form.Group>
        {ListResource}
        <Button size={'mini'} secondary floated='right' onClick={onAddResource}>
          Agregar Recurso
        </Button>
      </Form>
    </Segment>
  )
}

const Resource = ({resource, onDelete, onChange}) => {

  const {name, link} = resource

  return (
    <Form.Group widths='equal'>
      <Form.Input placeholder='Nombre' value={name} onChange={(e)=>onChange('name', e.target.value)} />
      <Form.Input placeholder='Link' value={link} onChange={(e)=>onChange('link', e.target.value)} />
      <Button size={'mini'} icon negative onClick={onDelete} >
        <Icon name='trash' />
      </Button>
    </Form.Group>
  )
}

const ModalConfirm = ({open, onClose}) => {
  return (
    <Modal
      size={'tiny'}
      open={open}
      closeOnDimmerClick={false}
      onClose={onClose}
    >
      <Modal.Header></Modal.Header>
      <Modal.Content>
        <p>Se actualizó correctamente los recursos</p>
      </Modal.Content>
      <Modal.Actions>
        <Button secondary onClick={onClose}>
          Ok
        </Button>
      </Modal.Actions>
    </Modal>
  )
}