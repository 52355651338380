import React from 'react'

export default (props) => {
  const {value} = props
  const { text, color, bold, typography, size, margin } = value
  
  const marginBottom = margin?Number.parseInt(margin): 10
  const colorStyle = color ? color : '#fff'
  let sizeStyle = 16
  
  if(typography === 'text'){
    sizeStyle = size ? size : 16
  }else if(typography === 'H3'){
    sizeStyle = 21
  }else if(typography === 'H2'){
    sizeStyle = 24
  }else if(typography === 'H1'){
    sizeStyle = 27
  }

  let style = {
    margin: '0px 20px', 
    paddingBottom: marginBottom+'px', 
    color: colorStyle, 
    fontSize: sizeStyle+'px', 
    fontFamily: 'Delicious-Heavy'
  }
  if(bold){
    style['fontWeight'] = 'bold'
  }

  return (
    <div style={style} >
      {text}
    </div>
  )

}