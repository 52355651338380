import React from 'react'
import { Segment, Form, Label, Button, Icon, Grid, Input, Header } from 'semantic-ui-react'

const Editorial = (props) => {

  const { value, index, onChange, onClick, onClickUp, onClickDown } = props

  const addRol = () => {
    if (!value.roles) {
      value.roles = []
    }
    value.roles.push({})
    onChange(value.roles, index, 'roles')
  }

  const removeRol = (indexContent) => {
    value.roles.splice(indexContent, 1)
    onChange(value.roles, index, 'roles')
  }

  const onChangeRol = (newValue, indexContent, field) => {
    value.roles[indexContent][field] = newValue
    onChange(value.roles, index, 'roles')
  }

  const onChangeGroup = (newValue, index, group, field) => {
    let groupValue = value[group]
    if (!groupValue) {
      groupValue = {}
    }
    groupValue[field] = newValue
    onChange(groupValue, index, group)
  }

  let label = value.label ? value.label : ''

  let editionName = value.edition && value.edition.name ? value.edition.name : ''
  let editionLabel = value.edition && value.edition.label ? value.edition.label : ''
  let editionEmail = value.edition && value.edition.email ? value.edition.email : ''
  let editionPhone = value.edition && value.edition.phone ? value.edition.phone : ''
  let editionWeb = value.edition && value.edition.web ? value.edition.web : ''

  let messageLabel = value.message && value.message.label ? value.message.label : ''
  let messageText = value.message && value.message.text ? value.message.text : ''

  return (
    <Segment clearing>
      <Button size={'mini'} icon negative floated='right' onClick={() => onClick(index)} >
        <Icon name='remove' />
      </Button>
      <Button size={'mini'} icon basic floated='right' onClick={() => onClickDown(index)}>
        <Icon name='chevron down' />
      </Button>
      <Button size={'mini'} icon basic floated='right' onClick={() => onClickUp(index)}>
        <Icon name='chevron up' />
      </Button>
      <Label >
        Editorial
      </Label><br /><br />
      <Form>
        <Form.Group widths='equal'>
          <Form.Input fluid label='Título' placeholder='Título'
            value={label} onChange={(e) => onChange(e.target.value, index, 'label')} />
        </Form.Group>
        <Form.Group widths='equal'>
          <Form.Input fluid label='Título de edición' placeholder='Título de edición'
            value={editionLabel} onChange={(e) => onChangeGroup(e.target.value, index, 'edition', 'label')} />
        </Form.Group>
        <Form.Group widths='equal'>
          <Form.Input fluid label='Nombre de edición' placeholder='Nombre de edición'
            value={editionName} onChange={(e) => onChangeGroup(e.target.value, index, 'edition', 'name')} />
        </Form.Group>
        <Form.Group widths='equal'>
          <Form.Input fluid label='Correo de edición' placeholder='Correo de edición'
            value={editionEmail} onChange={(e) => onChangeGroup(e.target.value, index, 'edition', 'email')} />
          <Form.Input fluid label='Teléfono de edición' placeholder='Teléfono de edición'
            value={editionPhone} onChange={(e) => onChangeGroup(e.target.value, index, 'edition', 'phone')} />
          <Form.Input fluid label='Página web de edición' placeholder='Página web de edición'
            value={editionWeb} onChange={(e) => onChangeGroup(e.target.value, index, 'edition', 'web')} />
        </Form.Group>
      </Form>
      <Header as='h5'>Roles</Header>
      <Segment.Group>
        <Roles roles={value.roles} removeRol={removeRol} onChangeRol={onChangeRol} />
        <Segment >
          <Grid relaxed='very'>
            <Grid.Column floated='right' >
              <Button secondary size={'mini'} icon floated='right' onClick={addRol}>
                Agregar Rol<Icon name='plus' />
              </Button>
            </Grid.Column>
          </Grid>
        </Segment>
      </Segment.Group>
      <Form>
        <Form.Group widths='equal'>
          <Form.Input fluid label='Título mensaje final' placeholder='Título mensaje final'
            value={messageLabel} onChange={(e) => onChangeGroup(e.target.value, index, 'message', 'label')} />
        </Form.Group>
        <Form.Group widths='equal'>
          <Form.TextArea label='Mensaje final' placeholder='Mensaje final'
            value={messageText} onChange={(e) => onChangeGroup(e.target.value, index, 'message', 'text')} />
        </Form.Group>
      </Form>
    </Segment>
  )
}

const Roles = (props) => {

  const { roles, removeRol, onChangeRol } = props

  return roles ? roles.map((data, index) => {

    let text = data.text ? data.text : ''
    let label = data.label ? data.label : ''

    return (
      <Segment key={index} >
        <Grid>
          <Grid.Column floated='left' width={7}>
            <Input placeholder='Título rol' label='Título rol' fluid
              value={label} width={7} onChange={(e) => onChangeRol(e.target.value, index, 'label')} />
          </Grid.Column>
          <Grid.Column floated='left' width={7}>
            <Input placeholder='Rol' label='Rol' fluid
              value={text} width={7} onChange={(e) => onChangeRol(e.target.value, index, 'text')} />
          </Grid.Column>
          <Grid.Column floated='right' width={2}>
            <Button size={'mini'} icon negative floated='right' onClick={() => removeRol(index)} >
              <Icon name='minus' />
            </Button>
          </Grid.Column>
        </Grid>
      </Segment>
    )
  }) : null
}

export default Editorial 