import React, {useState} from 'react'
import { Segment, Form, Modal } from 'semantic-ui-react'
import AdView from '../AdView'
import ImageUpload from '../components/ImageUpload'
import PageTitle from '../components/PageTitle'

const Ad = (props) => {

  const { page, selectedPage, onChangePage } = props
  const [open, setOpen] = useState(false)

  const onChangeMagazinePage = (value, field) => {
    page[field] = value
    onChangePage(selectedPage, page)
  }

  const onChangeMagazinePageData = (value, field) => {
    page.data[field] = value
    onChangePage(selectedPage, page)
  }

  const onChangeImage = (value) => {
    page.data.image = value
    onChangePage(selectedPage, page)
  }

  let image = page.data ? page.data.image : page.data

  return (
    <React.Fragment>
      <Segment>
        Información principal del Aviso<br/><br/>
        <PageTitle value={page.title} onChange={onChangeMagazinePage} />
      </Segment>
      <Segment>
        <Form>
          <ImageUpload image={image} onChange={onChangeImage} />
          <Form.Input label='Link' placeholder='Link' value={page.data.link} 
            onChange={(e) => onChangeMagazinePageData(e.target.value, 'link')} />
          <Form.Input label='YouTube (ID)' placeholder='YouTube (ID)' value={page.data.youtube} 
            onChange={(e) => onChangeMagazinePageData(e.target.value, 'youtube')} />
        </Form>
      </Segment>
      <Modal
        size='tiny'
        open={open}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
      >
        <Modal.Header>Previsualizar</Modal.Header>
        <Modal.Content style={{backgroundColor: '#59514e'}}>
          <AdView page={page} />
        </Modal.Content>
      </Modal>
    </React.Fragment>
  )
}

export default Ad