import React from 'react'

export default (props) => {
  const {value} = props
  const { content, title, margin, titleBackgroundColor, titleColor, titleBold, 
    elementBackgroundColor, elementColor, elementBold } = value
  let marginBottom = margin?Number.parseInt(margin): 15
  let titleBackgroundColorStyle = titleBackgroundColor?titleBackgroundColor: '#39864B'
  let titleColorStyle = titleColor?titleColor: '#FFF'
  let elementBackgroundColorStyle = elementBackgroundColor?elementBackgroundColor: '#BFC0BF'
  let elementColorStyle = elementColor?elementColor: '#000'
  
  const contentComponent = content?content.map((data, index)=>{
    return <div key={index}>{data.text}</div>
  }):null

  let style = {
    margin: '0px 20px', 
    paddingBottom: marginBottom+'px'
  }

  let style2 = {
    padding: '10px', 
    color: titleColorStyle,
    backgroundColor: titleBackgroundColorStyle,
    fontSize: '21px', 
    fontFamily: 'Swiss721BT-BoldCondensed'
  }
  if(titleBold){
    style2['fontWeight'] = 'bold'
  }

  let style3 = {
    padding: '10px', 
    color: elementColorStyle,
    backgroundColor: elementBackgroundColorStyle,
    fontSize: '16px', 
    fontFamily: 'Swiss721BT-Roman'
  }
  if(elementBold){
    style3['fontWeight'] = 'bold'
  }

  return (
    <div style={style} >
      <div style={style2}>
        {title}
      </div>
      <div style={style3}>
        {contentComponent}
      </div>
    </div>
  )

}