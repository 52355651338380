import React, {useState, useEffect} from 'react'
import { Segment, Form, Label, Button, Icon, Input } from 'semantic-ui-react'
import RichTextEditor from 'semantic-ui-react-rte'

const Paragraph = (props) => {

  const { value, index, onChange, onClick, onClickUp, onClickDown } = props
  let margin = value.margin?value.margin:''
  let paragraphColor = value.paragraphColor?value.paragraphColor:''

  return (
    <Segment clearing>
      <Button size={'mini'} icon negative floated='right' onClick={()=>onClick(index)} >
        <Icon name='remove' />
      </Button>
      <Button size={'mini'}  icon basic floated='right' onClick={()=> onClickDown(index)}>
        <Icon name='chevron down' />
      </Button>
      <Button size={'mini'}  icon basic floated='right' onClick={()=> onClickUp(index)}>
        <Icon name='chevron up' />
      </Button>
      <Label >
        Párrafo
      </Label><br/><br/>
      <Form>
        <Form.Group widths='equal'>
          <Form.Field width={4}>
            <label>Color de Parrafo</label>
            <Input placeholder='Parrafo' labelPosition='right' 
              label={<Label style={{backgroundColor: paragraphColor, border: '1px solid rgba(34,36,38,.15)'}}>&nbsp;</Label>}
              onChange={(e) => onChange(e.target.value, index, 'paragraphColor')} value={paragraphColor} />
          </Form.Field>
          <Form.Field width={3}>
            <label>Margen</label>
            <Input placeholder='Margen' 
              onChange={(e) => onChange(e.target.value, index, 'margin')} value={margin} />
          </Form.Field>
        </Form.Group>
        <Rich text={value.text} text2={value.text2} onChange={onChange} index={index}  />
      </Form>
    </Segment>
  )
}

export default Paragraph

const Rich = (props) => {

  const {text, text2, onChange, index} = props
  const [richText, setRichText] = useState(RichTextEditor.createEmptyValue())

  useEffect(() => {
    if(text2){
      setRichText(RichTextEditor.createValueFromString(text2, 'html'))
    }else{
      if(text){
        setRichText(RichTextEditor.createValueFromString(text, 'html'))
      }
    }
    // eslint-disable-next-line
  }, [text])

  const onChangeRich = (value) => {
    setRichText(value)
    onChange(value.toString('html'), index, 'text2')
  }

  return (
    <RichTextEditor
      value={richText}
      onChange={onChangeRich}
    />
  )

}