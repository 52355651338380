import React, { useState, useEffect } from 'react'
import { storage } from '../../../../firebase-init'
import axios from 'axios'

export default (props) => {
  const {value} = props
  const { image, margin } = value
  const [ srcImage, setSrcImage ] = useState()
  
  let marginBottom = margin?Number.parseInt(margin): 15
  
  let style = {
    margin: '0px 20px', 
    paddingBottom: marginBottom+'px'
  }

  useEffect(() => {
    if(image){
      const {file, path} = image
      if(file){
        let reader = new FileReader()
        reader.onload = function (e) {
          setSrcImage(e.target.result)
        }
        reader.readAsDataURL(file)
      }else if(path){
        storage.ref(path).getDownloadURL().then(function (url) {
          axios.get('https://us-central1-gold-magazine.cloudfunctions.net/exportImageBase64', {
            params: {
              url: url
            }
          }).then((response)=>{
            const {base64} = response.data
            setSrcImage('data:image/jpeg;base64,'+base64)
          })
        })
      }
    }else{
      setSrcImage(null)
    }
  }, [image])

  let imgComponent = null
  if(srcImage){
    imgComponent = <img style={{width: '100%', backgroundColor: '#1a73e8'}} src={srcImage} alt='' />
  }

  return (
    <div style={style} className='ad-magazine' >
      {imgComponent}  
    </div>
  )

}