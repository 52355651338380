import React from 'react'
import { Form } from 'semantic-ui-react'

const PageTitle = (props) => {

  const { value, onChange } = props

  return (
    <Form>
      <Form.Group widths='equal' >
        <Form.Input label='Título' placeholder='Título' fluid
          onChange={(e) => onChange(e.target.value, 'title')} value={value} />
      </Form.Group>
    </Form>
  )
}

export default PageTitle