import React from 'react'

export default (props) => {
  const {value} = props
  const { text, margin } = value

  const marginBottom = margin?Number.parseInt(margin): 25
  
  let style = {
    marginTop: '-15px', 
    paddingBottom: marginBottom+'px',
    marginLeft: '10px'
  }

  return (
    <div style={style}>
      <span className='section-magazine'>{text}</span>
    </div>
  )

}