export const postonTemplate = {
  'bookmark': false,
  'title': 'Postón',
  'type': 'poston',
  'data': [
    {
      'type': 'logo'
    },
    {
      'address': 'Av. Canadá 1221 Urb. Santa Catalina - La Victoria - Lima 13',
      'web': 'www.isem.org.pe',
      'email': 'isem@isem.irg.pe',
      'name': 'Publicación del Instituto de Seguridad Minera - ISEM',
      'description': 'El Instituto de Seguridad Minera-ISEM es una organización fundada en 1998 por iniciativa del Ministerio de Energía y Minas, la Sociedad Nacional de Minería Petróleo y Energía, el Instituto de Ingenieros de Minas del Perú y el Colegio de Ingenieros del Perú.',
      'phone': '4371300',
      'type': 'institute'
    },
    {
      'type': 'institute-directory',
      'roles': [
        {
          'label': 'Gerente',
          'text': 'Ing. Fernando Borja Añorga'
        },
        {
          'text': 'Ing. Fiori Ramos Montañes',
          'label': 'Responsable de Capacitación'
        },
        {
          'text': 'Dr. José Valle Bayona',
          'label': 'Responsable de Seguridad, Higiene, Salud Ocupacional y Medio Ambiente'
        },
        {
          'text': 'Lic. Rosanita Witting Muller',
          'label': 'Responsable de Eventos'
        }
      ],
      'president': {
        'text': 'Ing. Roque Benavides Ganoza',
        'label': 'Presidente'
      },
      'label': 'DIRECTORIO ISEM',
      'rolesless': {
        'data': [
          'Ing. Alfredo Rodríguez Muños',
          'Ing. Benjamín Jaramillo Molina',
          'Ing. Juan Dumler Cuya',
          'Ing. Juan José Herrera Távara',
          'Ing. Luis Arguelles',
          'Ing. Roberto Maldonado Astorga',
          'Ing. Russell Marcelo Santillana Salas',
          'Ing. Tomás Chaparro'
        ],
        'label': 'Directores'
      }
    },
    {
      'type': 'companies',
      'text': 'Unión Andina de Cementos S.A.A. - U.M. Planta Atocongo (Unacem S.A.A.); Cía. Minera Poderosa S.A.; Minera Colquisiri S.A.; Southern Peru Copper Corporation - U.M. Ilo; Southern Peru Copper Corporation - U.M. Toquepala; Southern Peru Copper Corporation - U.M. Cuajone; Southern Peaks Mining Perú - SPM Perú S.A.C.; Empresa Minera Los Quenuales S.A. - U.M. Iscaycruz; Empresa Minera Los Quenuales S.A. - U.M. Yauliyacu; Consorcio de Ingenieros Ejecutores Mineros S.A. (Ciemsa) - U.M. Tacaza; Shougang Hierro Perú S.A.; Pan American Silver Huaron S.A. - U.M. Huarón; Compañía Minera Argentum S.A. - U.M. Morococha; Minsur S.A. - Planta de Fundición Pisco; Minsur S.A - U.M. San Rafael; Minsur S.A. - U.M. Pucamarca; Compañía Minera Miski Mayo S.R.L.; Cía. Minera Antapaccay S.A. - Antapaccay; Compañía Minera Ares S.A. - U.M. Pallancata; Compañía Minera Ares S.A.; U.M. Inmaculada; Sociedad Mi- nera Cerro Verde S.A.A.; Cía. de Minas Buenaventura S.A.A. - U.M. Uchuccha- cua; Cía. de Minas Buenaventura S.A.A. - U.M. Orcopampa / U.M. Tambomayo; Cía. de Minas Buenaventura S.A.A. - U.M. Julcani; Gold Fields La Cima S.A. - U.M. Cerro Corona; Minera Aurífera Retamas S.A.; Compañía Minera Antamina S.A. - U.M. Yanacancha; Sociedad Minera Corona S.A. - U.M. Yauricocha; La Arena S.A.; Impala Terminals Perú S.A.C.; Compañía Minera Condestable S.A.; Shahuindo S.A.C. - U.M. Tahoe Perú Shahuindo; Minera Bateas S.A.C. - U.M. San Cristóbal; Volcan Compañía Minera S.A.A.; Cía. Minera Santa Luisa S.A. - U.M. Huanzala; Cía. Minera Raura S.A.; Aruntani S.A.C. - U.M. Tucari; Minera La Zanja S.R.L.; Minera Chinalco Perú S.A.; Nexa Resources Perú S.A.A. - U.M. Atacocha; Nexa Resources Perú S.A.A. - U.M. Porvenir; Nexa Resources Perú S.A.A. - U.M. Cerro Lindo; Nexa Resources Perú S.A.A. - Oficina Corporativa.',
      'label': 'EMPRESAS SOCIAS ACTIVAS'
    },
    {
      'type': 'partners',
      'label': 'SOCIOS ADHERENTES',
      'text': 'San Martín Contratistas Generales S.A.; Iesa S.A.; Anddes Asociados S.A.C.; Administración de Empresas S.A.C.; DSI Underground Perú S.A.C.; Mapfre Perú Vida Compañía de Seguros y Reaseguros; Famesa Explosivos S.A.C.; Explomin del Perú S.A.; CJ Netcom S.A.C.; Quick Rent a Car S.A.; Zicsa Contratistas Generales S.A.; Cosapi Minería S.A.C.; Exploraciones Mineras EA & T S.A.C.; Instituto de Educación Superior Tecnológico Público Espinar; Pevoex Contratistas S.A.C.'
    },
    {
      'message': {
        'label': 'Seguridad Minera ',
        'text': 'no se solidariza necesariamente con las opiniones vertidas en los artículos. Esta publicación no debe considerarse como un documento de carácter legal. ISEM no acepta ninguna responsabilidad surgida en cualquier forma de esta publicación. Hecho el Depósito Legal 98-3585.'
      },
      'label': 'REVISTA SEGURIDAD MINERA',
      'roles': [
        {
          'text': 'Marco Polo Santillán',
          'label': 'Director periodístico'
        },
        {
          'label': 'Gerente de Comunicación y Marketing',
          'text': 'Hilda Súares Cunza'
        },
        {
          'label': 'Editor web y redes sociales',
          'text': 'Nicolás Polo Suárez'
        },
        {
          'text': 'Archivo',
          'label': 'Foto carátula'
        },
        {
          'label': 'Diagramación',
          'text': 'Alejandro Zorogastúa Díaz'
        }
      ],
      'edition': {
        'label': 'Edición',
        'name': 'Centro de Información Tuminoticias S.A.C',
        'email': 'revista@isem.org.pe',
        'phone': '498-0393',
        'web': 'www.revistaseguridadminera.com'
      },
      'type': 'editorial'
    }
  ]
}