import React from 'react'
import { Segment, Form, Label, Button, Icon, Input } from 'semantic-ui-react'

const Section = (props) => {

  const { value, index, onChange, onClick, onClickUp, onClickDown } = props
  let margin = value.margin?value.margin:''

  return (
    <Segment clearing>
      <Button size={'mini'} icon negative floated='right' onClick={()=>onClick(index)} >
        <Icon name='remove' />
      </Button>
      <Button size={'mini'}  icon basic floated='right' onClick={()=> onClickDown(index)}>
        <Icon name='chevron down' />
      </Button>
      <Button size={'mini'}  icon basic floated='right' onClick={()=> onClickUp(index)}>
        <Icon name='chevron up' />
      </Button>
      <Label >
        Sección
      </Label><br/><br/>
      <Form>
        <Form.Group widths='equal'>
          <Form.Input fluid label='Texto' placeholder='Texto' 
          value={value.text?value.text:''} 
          onChange={(e) => onChange(e.target.value, index, 'text')} />
          <Form.Field width={3}>
            <label>Margen</label>
            <Input placeholder='Margen' 
              onChange={(e) => onChange(e.target.value, index, 'margin')} value={margin} />
          </Form.Field>
        </Form.Group>
      </Form>
    </Segment>
  )
}

export default Section