import React, {useEffect, useState} from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route, Redirect
} from "react-router-dom"
import Magazine from './features/Magazine'
import ListMagazine from './features/ListMagazine'
import Terms from './features/Terms'
import Login from './features/Login'
import CreateUser from './features/CreateUser'
import Resources from './features/Resources'
import Videos from './features/Videos'
import { auth } from './firebase-init'

function App() {

  const [userExist, setUserExist] = useState(false)
  const [loadingUser, setLoadingUser] = useState(false)

  useEffect(() => {
    const users = ['hPgYeSCkthV9aWxi8ORZdy06hBf2',
      'a6UmA3VJWQR35PZWetNlnmLVA5K2',
      'q2ynXWuoRVbCPP0vugqflFraKuk1'
    ]
    const isUserAdmin = (user) => {
      return user && users.find((value)=> value === user.uid)
    }
    const isNotUserAdmin = (user) => {
      return user && !users.find((value)=> value === user.uid)
    }
    const subscriber = auth.onAuthStateChanged((user) => {
      if(isUserAdmin(user)){
        setUserExist(true)
        setLoadingUser(true)
      }else if(isNotUserAdmin(user)){
        auth.signOut().then(()=>{
          window.location.href = "/"
        })
      }else{
        setLoadingUser(true)
      }
    })
    return subscriber
  }, [])

  if(!loadingUser){
    return null
  }

  return (
  	<Router>
      <div>
        <Switch>          
          <Route exact path="/magazine">
            { userExist?
              <ListMagazine /> : <Redirect to={{pathname: "/login"}}/>
            }
          </Route>
          <Route exact path="/magazine/:key">
            { userExist?
              <Magazine /> : <Redirect to={{pathname: "/login"}}/>
            }
          </Route>
          <Route path="/magazine/:key/:keyList">
            { userExist?
              <Magazine /> : <Redirect to={{pathname: "/login"}}/>
            }
          </Route>
          <Route exact path="/:dev/magazine">
            <ListMagazine />
          </Route>
          <Route exact path="/:dev/magazine/:key">
            <Magazine dev />
          </Route>
          <Route path="/:dev/magazine/:key/:keyList">
            <Magazine dev />
          </Route>
          <Route path="/terms">
            <Terms />
          </Route>
          <Route path="/login">
            <Login />
          </Route>
          <Route exact path="/" >
            { userExist?
              <Redirect to={{pathname: "/magazine"}}/> : <Redirect to={{pathname: "/login"}}/>
            }
          </Route>
          <Route path="/create-user">
            { userExist?
              <CreateUser /> : <Redirect to={{pathname: "/login"}}/>
            }
          </Route>
          <Route path="/resources">
            { userExist?
              <Resources /> : <Redirect to={{pathname: "/login"}}/>
            }
          </Route>
          <Route path="/videos">
            { userExist?
              <Videos /> : <Redirect to={{pathname: "/login"}}/>
            }
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
