import React from 'react'

export default (props) => {

  const { indexPage, evenPage, oddPage } = props

  const numberPage = Number.parseInt(indexPage) + 1
  const textPage = (numberPage % 2 === 0) ? evenPage : oddPage

  return (
    <div style={{ marginRight: 10, textAlign: 'right', height: 38 }}>
      <span className='numberPage' number-page={numberPage} >{textPage}</span>
    </div>
  )
}