import React from 'react'
import { Grid, Segment, Header, Form, Button, Checkbox, Modal, GridColumn, Icon, Input, Label } from 'semantic-ui-react'
import PDFUpload from './PDFUpload'
import { savePDF, deletePDF } from './logic'
import { useParams } from 'react-router-dom'

const MagazineInformation = (props) => {

  const { magazine, saveMagazine, onChangeState, keyMagazine, publishMagazine, published, despublishMagazine, openModalPublished, notifications, openLoading, closeLoading } = props
  const { title, subtitle, editionName, editionDate, oddPage, evenPage, payment, priceMagazine, backgroundColor, pathPDF } = magazine
  const { key, dev, keyList } = useParams()

  const onChange = (value, field) => {
    magazine[field] = value
    onChangeState({magazine})
  }

  const onChangeNotifications = (value, field) => {
    notifications[field] = value
    onChangeState({notifications})
  }

  const onStateModalPublished = (value) => {
    onChangeState({openModalPublished: value})
  }

  const onChangePDF = async (path, file) => {
    openLoading()
    if(path){
      await savePDF(path, file, dev, key, keyList)
      magazine['pathPDF'] = path
      onChangeState({magazine})
    }else{
      await deletePDF(dev, key, keyList)
      delete magazine['pathPDF']
      onChangeState({magazine})
    }
    closeLoading()
  }

  return (
 
    <Grid columns={1} padded>
      <Grid.Column>
        <Segment clearing>
        <Button primary floated='right' onClick={saveMagazine} disabled={published} >Guardar Revista</Button>
        {
          (()=>{
            if(!(keyMagazine==='create') && !published ){
              return <Button secondary floated='right' onClick={() => onStateModalPublished(true)} >Publicar Revista</Button>
            }
          })()
        }
        {
          (()=>{
            if(published){
              return( 
                <>
                  <Button secondary floated='right' onClick={despublishMagazine} >Despublicar Revista</Button>
                  <PDFUpload pdf={pathPDF} onChange={onChangePDF} />
                </>
              )
            }
          })()
        }
        
        <Header>Información General</Header>
        <Form>
          <Form.Group>
            <Form.Input label='Titulo' placeholder='Titulo' width={3}
              value={title?title:''} onChange={(e) => onChange(e.target.value, 'title')} />
            <Form.Input label='Subtitulo' placeholder='Subtitulo' width={3}
              value={subtitle?subtitle:''} onChange={(e) => onChange(e.target.value, 'subtitle')} />
            <Form.Input label='Fecha Edición' placeholder='Fecha Edición' width={2}
              value={editionDate?editionDate:''} onChange={(e) => onChange(e.target.value, 'editionDate')} />
            <Form.Input label='Edición' placeholder='Edición' width={2}
              value={editionName?editionName:''} onChange={(e) => onChange(e.target.value, 'editionName')} />
            <Form.Input label='Pagina Impar' placeholder='Pagina Impar' width={2}
              value={oddPage?oddPage:''} onChange={(e) => onChange(e.target.value, 'oddPage')} />
            <Form.Input label='Pagina Par' placeholder='Pagina Par' width={2} 
              value={evenPage?evenPage:''} onChange={(e) => onChange(e.target.value, 'evenPage')} />
            <Checkbox label='Edicion de Pago' checked={payment} onClick={(event, data) => {onChange(data.checked, 'payment')}}  width={1} /> 
            <Form.Input label='Precio($)' placeholder='$' width={1} 
              value={priceMagazine?priceMagazine:''} onChange={(e) => onChange(e.target.value, 'priceMagazine')} />
            <Form.Field width={2}>
              <label>Fondo</label>
              <Input placeholder='Fondo' labelPosition='right' 
                label={<Label style={{backgroundColor: backgroundColor? backgroundColor:'#59514e', border: '1px solid rgba(34,36,38,.15)'}}>&nbsp;</Label>}
                onChange={(e) => onChange(e.target.value, 'backgroundColor')} value={backgroundColor} />
            </Form.Field>
          </Form.Group>
        </Form>
        </Segment>
      </Grid.Column>
      <GridColumn>
        <Modal
        centered={false}
        open={openModalPublished}
        onClose={() => onStateModalPublished(false)}
        onOpen={() => onStateModalPublished(true)}
        >
        <Modal.Header>Confirme Publicación</Modal.Header>
        <Modal.Content>
          <Modal.Description>
          <Form>
            <Form.Field>
              <label>Titulo Notificacion</label>
              <input disabled={notifications?!notifications.enabled:true} value={notifications?notifications.title:''} onChange={(e) => onChangeNotifications(e.target.value, 'title')}  />
            </Form.Field>
            <Form.Field>
              <label>Cuerpo de Notificacion</label>
              <input disabled={notifications?!notifications.enabled:true} value={notifications?notifications.body:''} onChange={(e) => onChangeNotifications(e.target.value, 'body')}  />
            </Form.Field>
            <Form.Field>
              <Checkbox label='Enviar Notificaciones' checked={notifications?notifications.enabled:false}  onClick={(event, data) => { onChangeNotifications(data.checked, 'enabled') } } />
            </Form.Field>
          </Form>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button secondary onClick={() => onStateModalPublished(false)}>
            <Icon name='remove' /> No
          </Button>
          <Button primary onClick={publishMagazine}>
            <Icon name='checkmark' /> Si
          </Button>
        </Modal.Actions>
        </Modal>        
      </GridColumn>
    </Grid>
  )
}

export default MagazineInformation