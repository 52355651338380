import React from 'react';
import { Grid, Header, Segment } from 'semantic-ui-react'

export default () => {

  return (
    <Grid columns={1} padded>
      <Grid.Column>
        <Header as='h3' block>
          Política de privacidad
        </Header>
        <Segment>
          <h1>Revista Seguridad Minera</h1>
          Gracias por formar parte de la comunidad de la revista Seguridad Minera, publicación oficial del Instituto de Seguridad Minera. Estamos comprometidos a proteger su información personal y su derecho a la privacidad. Si tiene alguna pregunta o inquietud sobre nuestra política o nuestras prácticas con respecto a su información personal, comuníquese con nosotros en prensa@revistaseguridadminera.com.<br/>
          En esta política de privacidad, buscamos explicarte de la forma más clara posible qué información recopilamos, cómo la usamos y qué derechos tiene en relación con ella. Esperamos que se tome un tiempo para leerlo detenidamente, ya que es importante. Si hay algún término en esta política de privacidad con el que no está de acuerdo, deje de usar nuestros Sitios o Aplicaciones.
          <h2>1. ¿Qué información recopilamos?</h2>
          En resumen: recopilamos la información personal que nos proporciona voluntariamente cuando se registra en la aplicación o adquiere algún producto. La información personal que recopilamos puede incluir lo siguiente:<br/>
          Nombres, apellidos, correo electrónico, teléfono, foto de perfil, dirección y otros datos similares.<br/>
          Recopilamos la información necesaria para procesar un pago al realizar compras en la aplicación. Paypal almacena todos los datos para la transacción. Puede encontrar su polítiva de privacidad aquí: https://www.paypal.com/gi/webapps/mpp/ua/privacy-full.<br/>
          Datos de inicio de sesión en redes sociales u otros servicios. Brindamos la opción de registrarse utilizando detalles de la cuenta de redes sociales como Facebook y de servicios como Google.<br/>
          Toda la información que nos proporcione debe ser verdadera completa y precisa, y debe notificarnos cualquier cambio en dicha información personal. Usted acepta que Seguridad Minera divulgue la información personal que nos haya proporcionado, incluido el contenido de correos electrónicos, fotos de perfil, mensajes y datos ingresados en el sitio, si así lo exige la ley o por solicitud del gobierno, como una orden o según lo especificado por la legislación peruana, a cuyos tribunales esta política se somete.<br/>
          <h3>1.1 Información recopilada automáticamente</h3>
          En resumen: información como la dirección IP y/o las características del dispositivo se recopila automáticamente cuando utiliza la aplicación y sus funcionalidades.<br/>
          Recopilamos automáticamente información cuando visita o usa la aplicación. Esta información no revela su identidad específica, pero puede incluir información de uso y dispositivo, como su dirección IP, sistema operativo, nombre del dispositivo, ubicación, información sobre cómo y cuándo utiliza la aplicación, y alguna otra información técnica. Esta información es principalmente necesaria para mantener la seguridad y funcionamiento de los servicios, y análisis e informes.<br/>
          Como muchas empresas, también recopilamos información a través de cookies y tecnologías similares.<br/>
          <h3>1.2 Información recopilada a través de nuestras aplicaciones</h3>
          En resumen: podemos recopilar información sobre su dispositivo móvil, notificaciones automáticas y permisos de Facebook cuando utiliza nuestras aplicaciones. No le enviaremos spam ni venderemos su información personal con fines de comercialización.<br/>
          Dato de registro. En caso de error en la aplicación, recopilamos datos e información (a través de productos de terceros) en su teléfono llamado Datos de registro. Estos datos de registro pueden incluir información como el tipo de dispositivo, la versión del sistema operativo, la configuración de la aplicación, la hora y la fecha del error y otras estadísticas similares. Estos datos de registro se envían a nuestro backend para su análisis a fin de identificar y resolver cualquier problema con el uso de la aplicación y para mejorar la calidad general de nuestra aplicación.<br/>
          Notificaciones push. Es posible que solicitemos enviarle notificaciones automáticas sobre su cuenta o la aplicación móvil. Si desea optar por no recibir este tipo de comunicaciones, puede desactivarlas en la configuración de su dispositivo.<br/>
          Permisos de Facebook. De forma predeterminada, accedemos a la información básica de su cuenta de Facebook, incluido su nombre, correo electrónico, sexo, fecha de nacimiento, ciudad actual y URL de la foto de perfil, así como otra información que elija hacer pública. También podemos solicitar acceso a otros permisos relacionados con su cuenta, como amigos, registros y me gusta, y puede optar por otorgarnos o denegarnos el acceso a cada permiso individual. Para obtener más información sobre los permisos de Facebook, consulte la página de referencia de permisos de Facebook.<br/>
          <h2>2. ¿Cómo usamos su información?</h2>
          A continuación, detallamos el uso de la información recibida a través de la aplicación y sus funcionalidades:<br/>
          Enviarle comunicaciones de marketing y promocionales. Nosotros y / o nuestros socios de marketing externos podemos utilizar la información personal que nos envíe para nuestros fines de marketing. Si no está de acuerdo, puede optar por no recibir nuestros correos electrónicos de marketing en cualquier momento.<br/>
          Para enviarle información administrativa. Podemos utilizar su información personal para enviarle información sobre productos, servicios y nuevas funciones y / o información sobre cambios en nuestros términos, condiciones y políticas.<br/>
          Podemos utilizar su información para cumplir y administrar sus pedidos, pagos, devoluciones e intercambios realizados a través de los Servicios o Aplicaciones.<br/>
          Entregarle publicidad dirigida. Podemos utilizar su información para desarrollar y mostrar contenido y publicidad (y trabajar con terceros que lo hagan) adaptados a sus intereses y / o ubicación y para medir su efectividad.<br/>
          Administrar sorteos y concursos. Podemos usar su información para administrar sorteos y concursos cuando elija participar en concursos.<br/>
          Solicitar comentarios. Podemos utilizar su información para solicitar comentarios y comunicarnos con usted sobre su uso de nuestros Servicios o Aplicaciones.<br/>
          Para proteger nuestros Servicios. Podemos utilizar su información como parte de nuestros esfuerzos para mantener nuestros Servicios o Aplicaciones seguros y protegidos (por ejemplo, para la supervisión y prevención de fraudes).<br/>
          Para hacer cumplir nuestros términos, condiciones y políticas para fines comerciales, razones legales y contractuales.<br/>
          Para responder a solicitudes legales y prevenir daños. Si recibimos una citación u otra solicitud legal, es posible que debamos inspeccionar los datos que tenemos para determinar cómo responder.<br/>
          Para administrar cuentas de usuario. Podemos utilizar su información con el fin de administrar nuestra cuenta y mantenerla en funcionamiento.<br/>
          Prestar servicios al usuario. Podemos utilizar su información para brindarle el servicio solicitado.<br/>
          Responder a las consultas de los usuarios / ofrecer soporte a los usuarios. Podemos utilizar su información para responder a sus consultas y resolver cualquier problema potencial que pueda tener con el uso de nuestros Servicios.<br/>
          Podemos utilizar su información para otros fines comerciales, como análisis de datos, identificación de tendencias de uso, determinación de la efectividad de nuestras campañas promocionales y para evaluar y mejorar nuestros Servicios o Aplicaciones, productos, marketing y su experiencia. Podemos usar y almacenar esta información en forma agregada y anónima para que no esté asociada con usuarios finales individuales y no incluya información personal. No usaremos información personal identificable sin su consentimiento.<br/>
          <h2>3. ¿Se compartirá su información con alguien?</h2>
          Solo compartimos información con su consentimiento, para cumplir con las leyes, para brindarle servicios, para proteger sus derechos o para cumplir con obligaciones comerciales.<br/>
          Podemos procesar o compartir datos según la siguiente base legal:<br/>
          Consentimiento: podemos procesar sus datos si nos ha dado su consentimiento específico para usar su información personal con un propósito específico.<br/>
          Intereses legítimos: podemos procesar sus datos cuando sea razonablemente necesario para lograr nuestros intereses comerciales legítimos.<br/>
          Ejecución de un contrato: cuando hayamos celebrado un contrato con usted, podemos procesar su información personal para cumplir con los términos de nuestro contrato.<br/>
          Obligaciones legales: podemos divulgar su información cuando estemos legalmente obligados a hacerlo para cumplir con la ley aplicable, las solicitudes gubernamentales, un procedimiento judicial, una orden judicial o un proceso legal, como en respuesta a una orden judicial o una citación (incluso en respuesta a las autoridades para cumplir con los requisitos de seguridad nacional o de aplicación de la ley).<br/>
          Intereses vitales: podemos divulgar su información cuando creamos que es necesario para investigar, prevenir o tomar medidas con respecto a posibles violaciones de nuestras políticas, sospecha de fraude, situaciones que involucren amenazas potenciales a la seguridad de cualquier persona y actividades ilegales, o como evidencia en litigio en el que estamos involucrados.<br/>
          Podemos compartir sus datos con terceros, proveedores de servicios, contratistas o agentes que prestan servicios para nosotros o en nuestro nombre y requieren acceso a dicha información para realizar ese trabajo. Los ejemplos incluyen: procesamiento de pagos, análisis de datos, entrega de correo electrónico, servicios de alojamiento, servicio al cliente y esfuerzos de marketing. Podemos permitir que terceros seleccionados utilicen tecnología de seguimiento en los Servicios o Aplicaciones, lo que les permitirá recopilar datos sobre cómo interactúa con los Servicios o Aplicaciones a lo largo del tiempo. Esta información puede usarse para, entre otras cosas, analizar y rastrear datos, determinar la popularidad de cierto contenido y comprender mejor la actividad en línea. A menos que se describa en esta Política, no compartimos, vendemos, alquilamos ni intercambiamos su información con terceros con fines promocionales.<br/>
          Podemos utilizar empresas de publicidad de terceros para publicar anuncios cuando visita los Servicios o las Aplicaciones. Estas empresas pueden utilizar información sobre sus visitas a nuestro (s) sitio (s) web y otros sitios web que están contenidos en cookies web y otras tecnologías de seguimiento con el fin de proporcionar anuncios sobre bienes y servicios de su interés.<br/>
          <h2>4. ¿Cómo manejamos su registro a través de servicios externos?</h2>
          Nuestros Servicios o Aplicaciones le ofrecen la posibilidad de registrarse e iniciar sesión utilizando los detalles de su cuenta de servicios de terceros (como sus inicios de sesión en Facebook). Cuando elija hacer esto, recibiremos cierta información de perfil sobre usted de su proveedor de redes sociales. La información de perfil que recibimos puede variar según el proveedor de redes sociales en cuestión, pero a menudo incluirá su nombre, dirección de correo electrónico, lista de amigos, foto de perfil y otra información que elija hacer pública. Si inicia sesión con Facebook, también podemos solicitar acceso a otros permisos relacionados con su cuenta, como amigos, registros y me gusta, y puede optar por otorgarnos o denegarnos el acceso a cada permiso individual.<br/>
          Usaremos la información que recibimos solo para los fines que se describen en esta política de privacidad o que se le aclaran en los Servicios o Aplicaciones. Tenga en cuenta que no controlamos ni somos responsables de otros usos de su información personal por parte de su proveedor externo de redes sociales. Le recomendamos que revise su política de privacidad para comprender cómo recopilan, usan y comparten su información personal, y cómo puede establecer sus preferencias de privacidad en sus sitios y aplicaciones.<br/>
          <h2>5. ¿Cuál es nuestra postura frente a sitios web de terceros?</h2>
          La aplicación y sus servicios pueden contener anuncios de terceros que no están afiliados a nosotros y que pueden vincular a otros sitios web, servicios en línea o aplicaciones móviles. No podemos garantizar la seguridad y privacidad de los datos que proporcione a terceros. Los datos recopilados por terceros no están cubiertos por esta política de privacidad. No somos responsables del contenido o las prácticas y políticas de privacidad y seguridad de terceros, incluidos otros sitios web, servicios o aplicaciones que puedan estar vinculados. Debe revisar las políticas de dichos terceros y contactarlos directamente para responder a sus preguntas.<br/>
          <h2>6. ¿Cuánto tiempo guardamos su información?</h2>
          Conservamos su información durante el tiempo que sea necesario para cumplir con los propósitos descritos en esta política de privacidad, a menos que la ley exija lo contrario.<br/>
          Solo conservaremos su información personal durante el tiempo que sea necesario para los fines establecidos en esta política de privacidad, a menos que la ley exija o permita un período de retención más prolongado (como impuestos, contabilidad u otros requisitos legales). Ningún propósito en esta política requerirá que mantengamos su información personal por más tiempo que el período de tiempo en el que los usuarios tienen una cuenta con nosotros.<br/>
          <h2>7. ¿Cómo mantenemos segura su información?</h2>
          Hemos implementado medidas de seguridad técnicas y organizativas apropiadas diseñadas para proteger la seguridad de cualquier información personal que procesamos. Sin embargo, recuerde también que no podemos garantizar que Internet en sí sea 100% seguro. Aunque haremos todo lo posible para proteger su información personal, la transmisión de información personal hacia y desde nuestros Servicios o Aplicaciones es bajo su propio riesgo. Solo debe acceder a los servicios dentro de un entorno seguro.<br/>
          <h2>8. Información de la cuenta</h2>
          Si en algún momento desea revisar o cambiar la información de su cuenta o cancelar su cuenta, puede:<br/>
          <ul>
            <li>Inicie sesión en la configuración de su cuenta y actualice su cuenta de usuario.</li>
            <li>Comuníquese con nosotros utilizando la información de contacto proporcionada.</li>
          </ul>
          Si solicita cancelar su cuenta, desactivaremos o eliminaremos su cuenta e información de nuestras bases de datos activas. Sin embargo, es posible que se conserve cierta información en nuestros archivos para evitar fraudes, solucionar problemas, ayudar con cualquier investigación, hacer cumplir nuestros Términos de uso y / o cumplir con los requisitos legales.<br/>
          Puede darse de baja de nuestra lista de correo electrónico de marketing en cualquier momento haciendo clic en el enlace para darse de baja en los correos electrónicos que le enviamos o poniéndose en contacto con nosotros utilizando los detalles que se proporcionan a continuación. Luego será eliminado de la lista de correo electrónico de marketing; sin embargo, aún necesitaremos enviarle correos electrónicos relacionados con el servicio que son necesarios para la administración y el uso de su cuenta.<br/>
          <h2>9. Filtración de datos</h2>
          Una violación de la privacidad ocurre cuando hay acceso no autorizado o recopilación, uso, divulgación o eliminación de información personal. Se le notificará sobre violaciones de datos cuando Seguridad Minera crea que es probable que corra el riesgo de sufrir daños graves.<br/>
          <h2>10. ¿Actualizamos esta política?</h2>
          Es posible que actualicemos esta política de privacidad de vez en cuando. La versión actualizada se indicará con una fecha “Revisada” actualizada y la versión actualizada entrará en vigor tan pronto como esté disponible. Si realizamos cambios sustanciales a esta política de privacidad, podemos notificarle publicando un aviso de manera prominente de dichos cambios o enviándole directamente una notificación. Le recomendamos que revise esta política de privacidad con frecuencia para estar informado de cómo protegemos su información.<br/>
          <h2>11. ¿Cómo puede contactarnos sobre esta política?</h2>
          Si tiene preguntas o comentarios sobre esta política, puede enviarnos un correo electrónico a prensa@revistaseguridadminera.com o vía teléfono al (051)-4980393.<br/>
          <h3>Revista Seguridad Minera</h3>
        </Segment>
      </Grid.Column>
    </Grid>
  )
}