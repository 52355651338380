import React, { useState, useEffect } from 'react'
import { storage } from '../../../../firebase-init'
import axios from 'axios'

export default (props) => {
  const {value} = props
  const { paragraph, paragraph2, title, image, color, titleColor, paragraphColor, margin } = value
  const [ srcImage, setSrcImage ] = useState()
  
  let backgroundColor = color?color: '#45904A'
  let titleColorStyle = titleColor?titleColor: '#FFFFFF'
  let paragraphColorStyle = paragraphColor?paragraphColor: '#FFFFFF'
  let marginBottom = margin?Number.parseInt(margin): 15
  const firmText = ' <span class="firm-magazine">SM</span>'
  
  let style = {
    margin: '0px 20px', 
    paddingBottom: marginBottom+'px'
  }

  let style2 = {
    backgroundColor: backgroundColor, 
    padding: '15px'
  }

  let style3 = {
    marginBottom: '15px', 
    fontFamily: 'Delicious-Heavy',
    fontSize: '21px',
    color: titleColorStyle
  }

  let style4 = {
    margin: '0px',
    textAlign: 'justify', 
    fontFamily: 'Swiss721BT-Roman',
    fontSize: '16px',
    color: paragraphColorStyle
  }

  useEffect(() => {
    if(image){
      const {file, path} = image
      if(file){
        let reader = new FileReader()
        reader.onload = function (e) {
          setSrcImage(e.target.result)
        }
        reader.readAsDataURL(file)
      }else if(path){
        storage.ref(path).getDownloadURL().then(function (url) {
          axios.get('https://us-central1-gold-magazine.cloudfunctions.net/exportImageBase64', {
            params: {
              url: url
            }
          }).then((response)=>{
            const {base64} = response.data
            setSrcImage('data:image/jpeg;base64,'+base64)
          })
        })
      }
    }else{
      setSrcImage(null)
    }
  }, [image])

  let imgComponent = null
  if(srcImage){
    imgComponent = <img style={{marginLeft: '10px', width: '50%'}} src={srcImage} align="right" alt='' />
  }

  let textHtml = paragraph
  if(paragraph2){
    textHtml = paragraph2
  }
  textHtml = textHtml?textHtml.replaceAll('<p>','') : ''
  textHtml = textHtml?textHtml.replaceAll('</p>','') : ''

  return (
    <div style={style} >
      <div style={style2}>
        <div style={style3}>{title}</div>
        <div>
          {imgComponent}
          <p style={style4} dangerouslySetInnerHTML={{ __html: textHtml+firmText }} />
        </div>
      </div>
    </div>
  )

}