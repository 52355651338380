import React from 'react'
import { Grid } from 'semantic-ui-react'
import Publication from './Publication'
import Cover from './Cover'
import Content from './Content'
import Ad from './Ad'
import Poston from './Poston'

const MagazinePage = (props) => {

  const { magazine, selectedPage, selectedPublicationType, selectedPostonType, onChangeState } = props

  const onChangePage = (selectedPage, page) => {
    magazine.pages[selectedPage] = page
    onChangeState({ magazine, selectedPublicationType: null })
  }

  const onChangeSelectedPublicationType = (value) => {
    onChangeState({ selectedPublicationType: value })
  }

  const onChangeSelectedPostonType = (value) => {
    onChangeState({ selectedPostonType: value })
  }

  const page = (magazine.pages && magazine.pages[selectedPage]) ? magazine.pages[selectedPage] : null

  let pageComponent = null

  if (page) {
    const { type } = page
    if (type === 'cover') {
      pageComponent = <Cover page={page} selectedPage={selectedPage} onChangePage={onChangePage} />
    }
    if (type === 'content') {
      pageComponent = <Content page={page} selectedPage={selectedPage} onChangePage={onChangePage} />
    }
    if (type === 'ad') {
      pageComponent = <Ad page={page} selectedPage={selectedPage} onChangePage={onChangePage} />
    }
    if (type === 'publication') {
      pageComponent = <Publication page={page} selectedPage={selectedPage} selectedPublicationType={selectedPublicationType}
        onChangePage={onChangePage} onChangeSelectedPublicationType={onChangeSelectedPublicationType} 
        evenPage={magazine.evenPage} oddPage={magazine.oddPage}/>
    }
    if (type === 'poston') {
      pageComponent = <Poston page={page} selectedPage={selectedPage} onChangePage={onChangePage}
      selectedPostonType={selectedPostonType} onChangeSelectedPostonType={onChangeSelectedPostonType} />
    }
  }

  return (
    <Grid.Column width={11} 
      style={{ position: 'fixed', top: '190px', bottom: '0', overflowY: 'auto', marginLeft: '25%' }}>
      {pageComponent}
    </Grid.Column>
  )
}

export default MagazinePage