import React, {useEffect, useState} from 'react'
import { storage } from '../../../../firebase-init'
import { Image } from 'semantic-ui-react'
import axios from 'axios'

export default (props) => {

  const { page } = props
  const [imageAd, setimageAd] = useState()

  let backgroundColor = '#fff'
  if(page.color){
    backgroundColor = page.color
  }

  useEffect(() => {
    let isCancelled = false
    async function imageStorage(){
      if (page.data && page.data.image.path) {
        const { path, file } = page.data.image
        if(file){
          let reader = new FileReader()
          reader.onload = function (e) {
            setimageAd(e.target.result)
          }
          reader.readAsDataURL(file)
        }else{
          const url = await storage.ref(path).getDownloadURL()
          if(!isCancelled){
            const response = await axios.get('https://us-central1-gold-magazine.cloudfunctions.net/exportImageBase64', {
              params: {
                url: url
              }
            })
            const {base64} = response.data
            setimageAd('data:image/jpeg;base64,'+base64)
          }
        }
      }
    }
    imageStorage()
    return () =>{
      isCancelled = true
    }
  }, [page])

  return (
    <div id='magazine-pdf' style={{width: '360px', margin: 'auto', lineHeight: 'normal', 
      backgroundColor: backgroundColor}} >
      <Image src={imageAd} />
    </div>
  )
}
