import React, { useState, useEffect } from 'react';
import { Grid, Button, Header, Form, Icon, Segment, Modal} from 'semantic-ui-react'
import MagazineMenu from '../../components/MagazineMenu'
import { firestore, storage } from '../../firebase-init'
import ImageUpload from './ImageUpload'

const Videos = () => {

  const [state, setState] = useState([])
  const [open, setOpen] = useState(false)

  useEffect(()=> {
    const documentResources = firestore.collection('parameters').doc('videos')
    documentResources.get().then((document)=>{
      if(document.exists){
        const {list} = document.data()
        setState(list)
      }
    })
  },[])

  const onAddVideo = () => {
    state.unshift({
      image: {}, youtube: '', title: '', subtitle: '' 
    })
    setState([...state])
  }

  const onDeleteVideo = (index) => {
    state.splice(index, 1)
    setState([...state])
  }

  const onClickUpVideo = (index) => {
    const oldPosition = Number(index)
    const position = Number(index)-1
    if(position!==-1){
      const element = state.splice(oldPosition, 1)[0]
      state.splice(position, 0, element)
      setState([...state])
    }
  }

  const onClickDownVideo = (index) => {
    const oldPosition = Number(index)
    const position = Number(index)+1
    if(position!==state.length){
      const element = state.splice(oldPosition, 1)[0]
      state.splice(position, 0, element)
      setState([...state])
    }
  }

  const onChangeVideo = (index, key, value) => {
    state[index][key] = value
    setState([...state])
  }

  const onSaveVideos = async () => {
    await Promise.all(
      state.map(async (value) => {
        const {image} = value
        if(image && image.file){
          const { file, path } = image
          const storageRef = storage.ref(path)
          await storageRef.put(file)
        }
      })
    )

    const process = state.map((value)=>{
      const {youtube, title, subtitle} = value
      return {image: {path: value.image.path}, youtube, title, subtitle}
    })

    const documentResources = firestore.collection('parameters').doc('videos')
    await documentResources.set({list: process})
    setOpen(true)
    setState([...process])
  }

  const disabledSave = () => {
    let valid = false

    state.forEach((value)=>{
      const {image, youtube, title, subtitle} = value
      if(youtube ==='' || title ==='' || subtitle ==='' || !image || !image.path){
        valid = true
      }
    })

    return valid
  }

  const ListVideo = state.map((video, index)=> {
    return <Video key={index} video={video} 
      onChange={(key, value)=>onChangeVideo(index, key, value)}
      onDelete={()=> onDeleteVideo(index)}
      onClickDown={()=> onClickDownVideo(index)}
      onClickUp={()=> onClickUpVideo(index)} />
  })

  return (
    <MagazineMenu>
      <Grid columns={1} padded>
        <Grid.Column>
          <Header as='h3' block>
            Videos
          </Header>
          <Form>
            <Button secondary onClick={onAddVideo}>Agregar Video</Button>
            <Button primary onClick={onSaveVideos} disabled={disabledSave()}>Guardar Videos</Button>
          </Form>
        </Grid.Column>
      </Grid>
      <Grid columns={1} padded>
        <Grid.Column
          style={{ position: 'fixed', top: '120px', bottom: '0', overflowY: 'auto', width: '95%' }}>
          {ListVideo}
        </Grid.Column>
      </Grid>
      <ModalConfirm open={open} onClose={()=> setOpen(false)} />
    </MagazineMenu>
  )
}

export default Videos

const Video = ({video, onDelete, onClickDown, onClickUp, onChange}) => {

  const {image, youtube, title, subtitle} = video

  const onChangeImage = (value) => {
    onChange('image', value)
  }

  return (
    <Segment clearing>
      <Button size={'mini'} icon negative floated='right' onClick={onDelete} >
        <Icon name='remove' />
      </Button>
      <Button size={'mini'}  icon basic floated='right' onClick={onClickDown}>
        <Icon name='chevron down' />
      </Button>
      <Button size={'mini'}  icon basic floated='right' onClick={onClickUp}>
        <Icon name='chevron up' />
      </Button>
      <br/><br/>
      <Form>
        <Form.Group widths='equal'>
          <ImageUpload image={image} onChange={onChangeImage} />
          <Form.Input label='YouTube (ID)' placeholder='YouTube (ID)' value={youtube} onChange={(e)=>onChange('youtube', e.target.value)} />
        </Form.Group>
        <Form.Group widths='equal'>
          <Form.Input placeholder='Titulo' value={title} onChange={(e)=>onChange('title', e.target.value)} />
          <Form.Input placeholder='Subtitulo' value={subtitle} onChange={(e)=>onChange('subtitle', e.target.value)} />
        </Form.Group>
      </Form>
    </Segment>
  )
}

const ModalConfirm = ({open, onClose}) => {
  return (
    <Modal
      size={'tiny'}
      open={open}
      closeOnDimmerClick={false}
      onClose={onClose}
    >
      <Modal.Header></Modal.Header>
      <Modal.Content>
        <p>Se actualizó correctamente los videos</p>
      </Modal.Content>
      <Modal.Actions>
        <Button secondary onClick={onClose}>
          Ok
        </Button>
      </Modal.Actions>
    </Modal>
  )
}